import React from 'react';
import Nav from '../components/ui/Nav'
const Terms = () => {
    return ( 
        <>
        <Nav/>
        <div className='terms-container'>
            <h1>TÉRMINOS Y CONDICIONES — TEAMS</h1>
            <h3>Esta política de condiciones de uso es válida a partir del Jul 2023.</h3>
            <p>
                TEAMS, describe, a través de este documento, las normas de uso del sitio teamssocialapp.com y de cualquier otro sitio web, tienda o aplicación operada por el titular.
                Al navegar por este sitio web, consideramos que está de acuerdo con las condiciones de uso que figuran a continuación.
                Si no está de acuerdo con los términos de este acuerdo, le pedimos que no haga ningún otro uso de este sitio web, mucho menos que se registre o envíe sus datos personales.
                Si cambiamos nuestras condiciones de uso, publicaremos el nuevo texto en este sitio web, con una fecha de revisión actualizada. Podemos modificar este documento en cualquier momento.
                Si hay un cambio significativo en los términos de este acuerdo, podemos informarle utilizando la información de contacto que tenemos en nuestra base de datos o mediante una notificación.
                El uso de este sitio web después de los cambios significa que usted acepta las condiciones de uso revisadas. Si, después de leer la versión revisada, no está de acuerdo con sus términos, por favor, termine su acceso.
            </p>
            <h2>Sección 1 - Usuario</h2>
            <p>
                El uso de este sitio web le otorga automáticamente la condición de Usuario e implica su plena aceptación de todas las directrices y condiciones incluidas en estas Condiciones.
            </p>
            <h2>Sección 2 - Adhesión junto con la política de privacidad</h2>
            <p>El uso de este sitio web implica la adhesión a estas Condiciones de Uso y a la versión más actualizada de la Política de Privacidad de TEAMS.</p>
            <h2>Sección 3 - Condiciones de acceso</h2>
            <p>
                En general, el acceso al sitio web de TEAMS es gratuito y no requiere registro previo.
                Sin embargo, para hacer uso de algunas funcionalidades, el usuario puede necesitar registrarse, creando una cuenta de usuario con un nombre de usuario y una contraseña de acceso.
                Es responsabilidad del usuario proporcionar únicamente información correcta, auténtica, válida, completa y actualizada, así como no revelar su nombre de usuario y contraseña a terceros. TEAMS no se hará responsable de identidades falsas, como así tampoco de los daños y perjuicios que pudieran derivar de ese obrar.
                Algunas partes de este sitio web ofrecen al usuario la opción de publicar comentarios en determinadas áreas.TEAMS no consiente la publicación de contenidos de carácter discriminatorio, ofensivo, o ilícito, o que infrinjan los derechos de autor o cualquier otro derecho de terceros.
                La publicación de cualquier contenido por parte del usuario de este sitio web, incluidos los mensajes y comentarios, implica una licencia no exclusiva, irrevocable e irreversible para su uso, reproducción y publicación por parte de TEAMS en su sitio web, plataformas de internet y aplicaciones, o incluso en otras plataformas, sin ninguna restricción o limitación.
            </p>
            <div>
                <h2>3.1 Derechos relacionados con el contenido de esta Aplicación - Todos los derechos reservados</h2>
                <p>
                    El Propietario posee y se reserva todos los derechos de propiedad intelectual de dicho contenido.
                    Por lo tanto, los usuarios no pueden usar dicho contenido de ninguna manera que no sea necesaria o implícita en el uso adecuado del Servicio.
                    En particular, pero sin limitación, los Usuarios no pueden copiar, descargar, compartir (más allá de los límites establecidos a continuación), modificar, traducir, transformar, publicar, transmitir, vender, sublicenciar, editar, transferir/asignar a terceros o crear obras derivadas del contenido disponible en esta Aplicación, ni permitir que ningún tercero lo haga a través del Usuario o su dispositivo, incluso sin el conocimiento del Usuario.
                    Donde se indique explícitamente en esta Aplicación, el Usuario puede descargar, copiar y/o compartir algún contenido disponible a través de esta Aplicación para su uso exclusivamente personal y no comercial y siempre que las atribuciones de derechos de autor y todas las demás atribuciones solicitadas por el Titular se implementen correctamente.
                </p>
                <h2>3.2 Contenido proporcionado por los usuarios</h2>
                <p>
                    El Titular permite a los Usuarios cargar, compartir o proporcionar su propio contenido a esta Aplicación.
                    Al proporcionar contenido a esta Aplicación, los Usuarios confirman que están legalmente autorizados para hacerlo y que no están infringiendo ninguna disposición legal y/o derechos de terceros.
                </p>
                <h2>3.3 Derechos sobre los contenidos proporcionados por los Usuarios</h2>
                <p>
                    Los usuarios reconocen y aceptan que al proporcionar su propio contenido en esta Aplicación, otorgan al Propietario una licencia no exclusiva, libre de regalías para procesar dicho contenido únicamente para la operación y el mantenimiento de esta Aplicación según lo requerido por contrato.
                    En la medida permitida por la ley aplicable, los Usuarios renuncian a cualquier derecho moral en relación con el contenido que proporcionan a esta Aplicación.
                    Los usuarios reconocen, aceptan y confirman que todo el contenido que proporcionan a través de esta Aplicación está sujeto a las mismas condiciones generales establecidas para el contenido de esta Aplicación.
                </p>
                <h2>3.4 Responsabilidad por el contenido proporcionado</h2>
                <p>
                    Los usuarios son los únicos responsables de cualquier contenido que carguen, publiquen, compartan o proporcionen a través de esta Aplicación. Los usuarios reconocen y aceptan que el Titular no filtra ni modera dichos contenidos.
                    Sin embargo, el Titular se reserva el derecho de remover, eliminar, bloquear o rectificar dicho contenido a su propia discreción y, sin previo aviso, denegar al Usuario que carga el acceso a esta Aplicación:
                </p>
                <ol type='A'>
                    <li>si se recibe alguna queja basada en dicho contenido;</li>
                    <li>si se recibe una notificación de infracción de los derechos de propiedad intelectual;</li>
                    <li>por orden de una autoridad pública;</li>
                    <li>
                        donde el Titular es consciente de que el contenido, aun siendo accesible a través de esta Aplicación, puede representar un riesgo para los Usuarios, terceros y/o la disponibilidad del servicio.
                        La retirada, supresión, bloqueo o rectificación de contenidos no dará derecho a los Usuarios que hayan facilitado dichos contenidos o que sean responsables de los mismos, a reclamación alguna de indemnización, perjuicio o reembolso.
                        Los usuarios aceptan eximir de responsabilidad al Propietario frente a cualquier reclamo presentado y/o daño sufrido debido al contenido que proporcionaron o proporcionaron a través de esta Aplicación.
                    </li>
                    </ol>
            </div>
                
                    <h2>Sección 4 - Cookies</h2>
                    <p>
                        La información sobre el uso que usted hace de este sitio web puede recogerse a través de las cookies. Las cookies son piezas de información que se almacenan directamente en el equipo que está utilizando. Las cookies permiten recoger información como el tipo de navegador, el tiempo de permanencia en el sitio web, las páginas visitadas, las preferencias de idioma y otros datos de tráfico anónimos. Nosotros y nuestros proveedores de servicios utilizamos la información para la protección de la seguridad, para facilitar la navegación, mostrar la información de manera más eficiente y personalizar su experiencia al utilizar este sitio web, así como para el seguimiento en línea. También recopilamos información estadística sobre el uso del sitio web para mejorar continuamente nuestro diseño y funcionalidad, para entender cómo se utiliza el sitio web y para ayudarle a resolver problemas relevantes.
                        Si no desea que se recoja su información a través de las cookies, existe un procedimiento sencillo en la mayoría de los navegadores que permite rechazar automáticamente las cookies, o le da la opción de aceptar o rechazar la transferencia de una cookie específica (o varias) de un sitio web concreto a su ordenador. Sin embargo, esto puede crear inconvenientes en su uso del sitio web.
                        La configuración que elija puede afectar a su experiencia de navegación y al funcionamiento que requiere el uso de cookies. En este sentido, rechazamos cualquier responsabilidad por las consecuencias derivadas del funcionamiento limitado de este sitio web causado por la desactivación de las cookies en su dispositivo (incapacidad para establecer o leer una cookie).
                    </p>
                    <h2>Sección 5 - Propiedad intelectual</h2>
                    <p>
                        Todos los elementos de TEAMS son propiedad intelectual de la misma o de sus licenciantes. Estos términos o el uso del sitio web no le otorgan ninguna licencia o derecho de uso de los derechos de propiedad intelectual de TEAMS o de cualquier tercero.
                        Sin perjuicio de cualquier disposición más específica de estos Términos, cualquier derecho de propiedad intelectual, como derechos de autor, derechos de marca registrada, derechos de patente y derechos de diseño relacionados con esta Aplicación son propiedad exclusiva del Propietario o sus licenciantes y están sujetos a la protección otorgada por leyes aplicables o tratados internacionales relacionados con la propiedad intelectual.
                        Todas las marcas comerciales, nominales o figurativas, y todas las demás marcas, nombres comerciales, marcas de servicio, marcas denominativas, ilustraciones, imágenes o logotipos que aparecen en relación con esta Aplicación son y seguirán siendo propiedad exclusiva del Propietario o sus licenciantes y están sujetos a la protección otorgada por las leyes aplicables o tratados internacionales relacionados con la propiedad intelectual.        
                    </p>
                    <h2>Sección 6 - Enlaces a sitios web de terceros</h2>
                    <p>
                        Este sitio web puede contener, de vez en cuando, enlaces de hipertexto que le redirigirán a sitios web de nuestros socios, anunciantes, proveedores, etc. Si hace clic en uno de estos enlaces a cualquiera de estos sitios, recuerde que cada sitio tiene sus propias prácticas de privacidad y que nosotros no somos responsables de estas políticas. Por favor, consulte esas políticas antes de enviar cualquier dato personal a esos sitios.
                        No somos responsables de las políticas y prácticas de recopilación, uso y divulgación (incluidas las prácticas de protección de datos) de otras organizaciones, como Facebook, Apple, Google, Microsoft, o cualquier otro desarrollador de software o proveedor de aplicaciones, tienda de medios sociales, sistema operativo, proveedor de servicios de internet o fabricante de dispositivos, incluidos los Datos Personales que usted divulgue a otras organizaciones a través de las aplicaciones, en relación con dichas aplicaciones, o publicados en nuestras redes sociales. Le recomendamos que se informe sobre la política de privacidad y las condiciones de uso de cada sitio web visitado o proveedor de servicios utilizado.
                    </p>
                    <h2>Sección 7 - Plazos y modificaciones</h2>
                    <p>
                        El funcionamiento de este sitio web es por tiempo indefinido.
                        La totalidad del sitio web o cada una de sus secciones podrá ser cerrada, suspendida o interrumpida unilateralmente por TEAMS, en cualquier momento y sin previo aviso.
                    </p>
                    <h2>Sección 8 - Datos personales</h2>
                    <p>
                        Durante el uso de este sitio web, ciertos datos personales serán recogidos y procesados por TEAMS y/o los Socios. Las normas relativas al tratamiento de los datos personales de TEAMS están estipuladas en la Política de Privacidad.
                    </p>
                    <h2>Sección 9- Limitación de responsabilidad </h2>
                    <p>En virtud de las disposiciones de estas disposiciones TEAMS no será responsables ante usted por:</p>
                    <ol>
                        <li>Ningún daño directo, indirecto, fortuito, especial, derivado ni ejemplar en que usted haya incurrido, sea cual sea su causa y bajo ninguna teoría de responsabilidad. Ello incluye, entre otras, cualquier pérdida de beneficios, ya sea directa o indirecta, cualquier pérdida de prestigio profesional o reputación empresarial, cualquier pérdida de datos por su parte, gastos del abastecimiento de bienes o servicios sustitutivos u otras pérdidas intangibles.</li>
                        <li>
                            Ninguna pérdida o daño en que usted pueda incurrir, incluidos entre otros pérdidas o daños originados como consecuencia de:
                            <ol type='a'>
                                <li>La confianza depositada por su parte en la exhaustividad, precisión o existencia de publicidad o como consecuencia de cualquier relación o transacción entre usted y otro anunciante o patrocinador cuya publicidad aparezca en los servicios;</li>
                                <li>Los cambios que TEAMS pueda incorporar en los servicios o la interrupción temporal o definitiva de estos o de alguna de sus funciones;</li>
                                <li>La eliminación o daño del contenido y de otros datos de divulgación que se alojan en los servicios o se ofrecen a través de estos, así como la imposibilidad de almacenarlos;</li>
                                <li>La negligencia por no haber preservado su contraseña o la información de su cuenta de forma segura y confidencial.</li>
                            </ol>
                        </li>
                    </ol>
                    <h2>Sección 10 - Contacto</h2>
                    <p>Si tiene alguna pregunta sobre las condiciones de uso, por favor póngase en contacto con nosotros por correo electrónico info@teamssocialapp.com.</p>
        </div>
        </>

     );
}
 
export default Terms;
