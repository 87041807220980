import React, {useEffect, useState} from 'react'
import firebase from '../../db/firebase';
import UserContext from './userContext';

const UserState = props => {
    const [userData, setUserData] = useState({
        session:false,
        uid:'',
        photo:'https://i.pinimg.com/236x/66/15/da/6615da7d0d3c74c0b538cd2974e5d4ed.jpg',
        emailVerified:false,
        userName:'',
        userCountry:'Argentina',
        admin:false,
        userEmail:'',
    })
    const code = {
        key1: 'Hn2euMEvUMdNCIeYG6xOad01gxM2',
        key2: 'QzWcIBK2crXjYDtkY4T6YeNwjwu1',
        key3: 'oJgcWJa1Olc58a6xyZSSIxfGuoE3',
        key4:'WJuqK1X5wnUuyFgLORk0wYdeZT72',
      };

    const checkSession = async () => {
        firebase.auth.onAuthStateChanged(async user =>{
            let urlPhoto = 'https://i.pinimg.com/236x/66/15/da/6615da7d0d3c74c0b538cd2974e5d4ed.jpg';
            if (user) {
                let isAdmin =  Object.values(code).includes(user.uid);
                await getImgUrlStorage(user.photoURL).then(t => {          
                    urlPhoto = t;
                    return urlPhoto;
                });
                setUserData({
                    ...userData,
                    session:true,
                    uid:user.uid,
                    photo:urlPhoto,
                    emailVerified:user.emailVerified,
                    userName:user.displayName,
                    admin:isAdmin,
                    userEmail:user.email
                });
                console.log(user.uid)
            }
            else setUserData({
                ...userData,session:false,
                uid:'',
                photo:'https://i.pinimg.com/236x/66/15/da/6615da7d0d3c74c0b538cd2974e5d4ed.jpg',
                emailVerified:false,
                userName:''
            });
        })
    }
    //IMG UPLOAD
    const uploadImageToStorage = async (path, imageName) => {
        const storageRef = firebase.storage.ref().child(imageName);
        await storageRef.putFile(path).catch(err => err.code);   
    }
    const getImgUrlStorage = async imageRoute => {
        const url = await firebase.storage.ref().child(imageRoute).getDownloadURL();
        return url;
    }
    const signIn = async (email,password) =>{
        let check = false;
        let errorAlert = '';
        await firebase.auth.signInWithEmailAndPassword(email,password)
                .then(async (userCredential) => {
                    console.log('Adentro');
                    check = await userCredential.user.emailVerified;
                    setUserData({...userData,session:true});
        
                })
                .catch(error => {return errorAlert = error.code})
            return {check:check,errorAlert:errorAlert};
           
    } 
    const signOut = async () => {
        firebase.auth
        .signOut()
        .then(() => {
            console.log('Afuera');
            checkSession();

        })
    }
    
    return ( 
        
        <UserContext.Provider
            value={{
                userPhoto:userData.photo,
                session:userData.session,
                userName:userData.userName,
                userUid:userData.uid,
                userEmail:userData.userEmail,
                emailVerified:userData.emailVerified,
                userCountry:userData.userCountry,
                admin:userData.admin,
                signIn,
                signOut,
                uploadImageToStorage,
                checkSession,
                setUserData
            }}
        >
            {props.children}
        </UserContext.Provider>

     );
}
 
export default UserState;
