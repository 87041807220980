import React from 'react'
import { Modal,Spinner,Text } from "native-base";

const ModalLoader = ({showModal}) =>{
    return(
        <Modal isOpen={showModal} >
            <Modal.Content>
            <Modal.Body bg={'orange.500'} flexDirection='row' alignItems='center' justifyContent='center'>
                <span class="loader-2"></span>
                <span class="loader-3">Load&nbsp;ng</span>
            </Modal.Body>
            </Modal.Content>
        </Modal>
    )
}
export default ModalLoader;