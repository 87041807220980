import React, { useState,useEffect, useContext } from 'react';
import { Box} from 'native-base';
import NavAdmin from '../../components/ui/NavAdmin';
import HomeAdmin from './HomeAdmin';
// import Moderation from './Moderation';
import MyTeams from './MyTeams';
import firebase from '../../db/firebase';
import ModalLoader from '../../components/modals/ModalLoader';
import IdeasAdmin from './IdeasAdmin';
import Points from './Points';
import UserContext from '../../contexts/user/userContext';
// import CreateAdmin from './CreateAdmin';
const DashboardAdmin = () => {
    const [component, setComponent] = useState(<MyTeams/>);
    const [page, setPage] = useState(4);
    const [admin, setAdmin] = useState(false);
    const {checkSession} = useContext(UserContext);

    const code = {
        key1: 'Hn2euMEvUMdNCIeYG6xOad01gxM2',
        key2: 'QzWcIBK2crXjYDtkY4T6YeNwjwu1',
        key3: 'oJgcWJa1Olc58a6xyZSSIxfGuoE3',
        key4:'WJuqK1X5wnUuyFgLORk0wYdeZT72',
    };

        useEffect(() => {
            const check = async () => {
                
                await checkSession();
        
            }

        check();
            
        }, []);


        useEffect(() => {
            firebase.auth.onAuthStateChanged(user =>{
                if (user) {

                    let isAdmin =  Object.values(code).includes(user.uid);
                    if(isAdmin) {
                        setAdmin(true)
                        return;
                    }
                    window.location.href = "/";
                    return;
                }else{
                    window.location.href = "/";
                }
            })
            
          
        }, []);

    
    return ( 
        <Box flexDirection={'row'}>
            {admin?(
                <>
                    <NavAdmin page={page} setPage={setPage} setComponent={setComponent}/>
                    <Box bg={'gray.200'} w='80%' h={'100vh'}>
                        {component}
                    </Box>
                </>
                    

            ):(
                <Box w={'100vw'} h={'100vh'} justifyContent='center' alignItems='center'>
                    <ModalLoader showModal={true}/>

                </Box>
            )}
        </Box>
     );
}
 
export default DashboardAdmin;