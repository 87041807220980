import React, { useContext, useEffect } from 'react'
import { Box, Heading, Text,Image, Button } from 'native-base';
import Nav from '../components/ui/Nav';
import { useParams } from 'react-router-dom';
import TeamsContext from '../contexts/teams/teamsContext';
import ModalLoader from '../components/modals/ModalLoader';
import { useState } from 'react';
import { FirebaseContext } from '../db/firebase';
import ModalLogin from '../components/modals/ModalLogin';
import Logo from '../sources/logo.svg';

const Team = ({mobile}) => {
    const {team,getTeam} = useContext(TeamsContext);
    let { id,country } = useParams();
    const [loader, setLoader] = useState(false);
    const [urlImg, setUrlImg] = useState('https://camarasal.com/wp-content/uploads/2020/08/default-image-5-1.jpg')
    const {firebase} = useContext(FirebaseContext);
    const [isSession, setIsSession] = useState(false);
    const [modalLogin, setModalLogin] = useState(false);
    // const [date, setDate] = useState(new Date());
    useEffect(() => {
        setLoader(true);

        getTeam(id,country.charAt(0).toUpperCase() + country.slice(1) );
        setLoader(false);


    }, []);

    useEffect(() => {
        getUrl();
        // if(team.dateMeet){
        //     let dateInfo= new Date(team.dateMeet.seconds * 1000 + Math.round(team.dateMeet.nanoseconds / 1000000));
        //     setDate(dateInfo)
        // };
    }, [team])
    

    useEffect(() => {
        setLoader(true);
        firebase.auth.onAuthStateChanged(user =>{
            if (user) {
                if(!user.emailVerified) {
                    setIsSession(true);
                     setLoader(false);

                    return;
                }
                setIsSession(true);
                setLoader(false);

                return;
            }else{
                setIsSession(false)
            }
        })
        setLoader(false);
      
    }, []);
    
   


    const getUrl= async () => {
        if(team.imgUrl){
            const url =  await firebase.storage.ref().child(team.imgUrl).getDownloadURL();
            setUrlImg(url);
        }
    }
    const detectMobileOS = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      
        // Detectar iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return 'iOS';
        }
      
        // Detectar Android
        if (/android/i.test(userAgent)) {
          return 'Android';
        }
      
        // Detectar otro sistema operativo
        return 'Otro';
      };
    return ( 
        <>
            {!mobile?(
                <Nav  />
            ):null}
            <ModalLogin showModal={modalLogin} setShowModal={setModalLogin}/>
            <ModalLoader showModal={loader} />

            <Box mt={mobile?null:'90px'}  px={mobile?'20px':'0px'}  h={mobile?'100vh':'89vh'} bg={'orange.600'} display={loader?'none':'flex'} alignItems={'center'} justifyContent={'center'} >
                <Image display={mobile?'flex':'none'} w={'80%'} h={'65px'} mb={10} src={Logo} alt='logo'/>
                
                <Box   p={6} bg={'white'} w={'100%'} maxW={'800px'} shadow={3} borderRadius={'20px'}>
                    <Box flexDirection={mobile?'column':'row'} alignItems={'center'} mb={2}>
                        <Image ml={-2} size={'2xl'} src={urlImg} borderRadius={'10px'} alt='photo activity'/>
                        <Box>
                            {/* <Text color={'gray.400'}>{date}</Text> */}
                        </Box>
                    </Box>
                    <Heading>{team.name}</Heading>
                    <Text mt={2}>{team.description}</Text>
                    <Button  display={mobile?'none':'flex'}  bg={'orange.500'} width={'100px'} alignSelf={'flex-end'} onPress={() => isSession? null :setModalLogin(true)}>
                        <Text bold color={'white'} >{!isSession?'Sign in':'Unirse +'}</Text>
                    </Button>
                </Box>
                <Button shadow={2} mt={10}  display={mobile?'flex':'none'} w={'80%'} borderRadius={'10px'} bg={'orange.500'}
                    onPress={() => {
                        const os = detectMobileOS()
                        if(os) window.location.href = "https://apps.apple.com/ar/app/teams-app/id6456361741";
                        else window.location.href = "/";
                    }}
                >
                    <Text fontSize={'20px'} color={'white'} bold>Descargar app</Text>
                </Button>
            </Box> 

        </>
    );
}
 
export default Team;