import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Image, Input, Select, Text, useToast, Modal, Pressable } from 'native-base';
import dbCategoryIcons from '../../db/locales/dbCategoryIcons';
import FileUploader from 'react-firebase-file-uploader';
import MapSelect from '../../components/ui/MapSelect';
import Loader from '../../components/ui/Loader';
import firebase from '../../db/firebase';

const ModalEditTeam = ({showModal, setShowModal,data}) => {

    const [loader, setLoader] = useState(false);
    //StateMap
    const [modalMap, setModalMap] = useState(false)
    //Date
    const [dateSelect, setDateSelect] = useState(currentDateTime)
    //State images
    const currentDateTime = new Date().toISOString().slice(0, 16);

    // const [upload, setUpload] = useState(false);
    // const [progress, setProgress] = useState(0);
    const [urlImg, setUrlImg] = useState('https://firebasestorage.googleapis.com/v0/b/teams-643cb.appspot.com/o/source%2FphotoDefault.png?alt=media&token=59b895d6-b969-4e25-a2c3-8b0e09613a39');
    //New Team
    const [dataTeam, setDataTeam] = useState({
        name:'',
        description:'',
        imgUrl:'',
        dateMeet: new Date(),
        time:'',
        cost: 0,
        // idUser:userId,
        instagram:'',
        discord:'',
        maxPeople:1,
        category:'',
        location:{latitude:0,longitude:0},
        countryLocation:'',
        state:'',
        address:'',
        city:'',
    });
    // const handleUploadStart = () =>{
    //     setProgress(0);
    //     setUpload(true);
    //     setLoader(true);

    // };
    // const handleUploadError = err =>{
    //     setUpload(true);
    //     console.log(err);
    //     setLoader(false);


    // };
    // const handleUploadSuccess = async filename =>{
    //     setProgress(100);
    //     setUpload(false);
    //     setLoader(false);
    //     const url = `ruta-base/${filename}`; // Reemplaza 'ruta-base' con la ruta real de tu servidor
    //     // setUrlImg(url); 
    //     console.log(url)

    // };
    // const handleProgress = n => {
    //     setProgress(n);
    //     console.log(n);
    // };
   
    //Toast
    const toast = useToast();
    const sendToast = (message,color) => {
        toast.show({
            render: () => {
                return(
                    <Box bg={color} px={'25px'} py='15px' rounded={16} shadow={2}>
                        <Text color='white' fontSize='16px' bold >{message}</Text>
                    </Box>
                );
            },
            placement: 'top'
        })
    }
    //Change color category
    useEffect(() => {
        if (dataTeam.category) {
            let arr = dbCategoryIcons.filter( c => c.code === dataTeam.category);
            setDataTeam({...dataTeam,categoryColor:arr[0].color})
            
        }
    }, [dataTeam.category]);
    //Date
    const handleDateTimeChange = (event) => {
        const inputValue = event.target.value;
        setDateSelect(inputValue)
        if (inputValue) {
          const timestamp = new Date(inputValue).getTime() / 1000; // Convertir a segundos
          const timestampValue = { seconds: timestamp, nanoseconds: 0 };
          setDataTeam({...dataTeam,dateMeet:timestampValue})
        }
      };
    //Upload Team
    const uploadTeam = async () => {

        if (dataTeam.name === '' || dataTeam.description === '' || dataTeam.category === ''|| dataTeam.imgUrl === '') {
            sendToast('Completa todo los campos','#E51E3B');
            return;
        }
        if (dataTeam.countryLocation === '') {
            sendToast('La ubicaciòn no pertenece a ningun pais','#E51E3B');
            return;
        }
        
        setLoader(true);

        try {
            await firebase.db.collection('teams'+ dataTeam.countryLocation.replace(/\s/g, "")).doc(data.id).update(dataTeam).catch(err => {
                console.log(err)
                setLoader(false);
                
            });
            // await firebase.storage.ref().child(data.imgUrl).put(urlImg).then(() => {
            //     console.log('subida')

            // })
            sendToast('Team Actualizado','green.500'); 
            setShowModal(false);
            setLoader(false);
            console.log('Creado con exito');
        } catch (error) {
            console.error(error);
            setLoader(false);
            return;
        }
               
    }
    useEffect(() => {
        if(data.name){
            setDataTeam({...dataTeam,imgUrl:data.imgUrl,dateMeet:data.dateMeet,name:data.name,category:data.category,description:data.description,cost:data.cost,discord:data.discord,instagram:data.instagram,whatsapp:data.whatsapp,maxPeople:dataTeam.maxPeople,address:data.address,city:data.city,state:data.state,countryLocation:data.countryLocation,location:{latitude:data.location.latitude,longitude:data.location.longitude}});
            
        }
        return;
    }, [data])
    useEffect(() => {
        if (data.dataMeet) {
            transformDate(data.dataMeet);
        }
    }, [data.dataMeet]);
    // useEffect(() => {
    //     if (data.imgUrl) {
    //     firebase.storage.ref().child(data.imgUrl).getDownloadURL()
    //     .then(url => {
    //         setUrlImg(url);
    //         console.log(url);
    //     })
    //     .catch(error => {
    //         console.error("Error al obtener la URL de descarga:", error);
    //     });
    //     }
    //     return;
        
    // }, [data.imgUrl])

    const transformDate = (dateMeet) => {
        const date = new Date(dateMeet.second * 1000 + dateMeet.nano / 1000000);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        dateSelect(`${year}-${month}-${day}T${hours}:${minutes}`);
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Se agrega 1 ya que los meses se indexan desde 0
        const day = now.getDate().toString().padStart(2, '0');
        const hour = now.getHours().toString().padStart(2, '0');
        const minute = now.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hour}:${minute}`;
      };
    
      // Establecer la fecha y hora actuales como valor por defecto al cargar el componente
      useEffect(() => {
        const currentDate = getCurrentDateTime();
        setDateSelect(currentDate);
      }, []);


    return ( 
        <Modal isOpen={showModal} opacity={1} >
            <Modal.Content>
                <Modal.Header flexDirection='row'  alignItems='center' justifyContent='space-between' px={4} py={2}>
                    <Pressable borderRadius={20} borderWidth={1} p={2}  bg='white' shadow={2} borderColor='#E51E3B'>
                        <Text color={'#E51E3B'}>Eliminar -</Text>
                    </Pressable>
                    <Text fontSize={'24px'} textAlign='center'>Editar actividad</Text>
                    <Pressable  onPress={() =>{ setShowModal(false)}} >
                       <Text>Cerrar</Text>
                    </Pressable>
                </Modal.Header>
                <Modal.Body>
                    <Box width='100%' alignItems={'center'}  justifyContent={'center'}>
                        <Loader showModal={loader}/>
                        <MapSelect showModal={modalMap} setShowModal={setModalMap} dataTeam={dataTeam} setChanges={setDataTeam}/>
                        <Box  bgColor={'gray.100'} justifyContent={'space-around'} borderRadius={10} borderWidth={1} borderColor={'black.100'}  shadow={2} p={5}>
                            <Box flexDirection={'column'} alignItems={'center'} w={'100%'} justifyContent={'space-between'}>
                                {/* <Box alignItems='center'>
                                    <Image source={{uri: urlImg}} borderRadius={10} w={'100%'} h={'200px'} mb={2} alt='Event'/>
                                    <FileUploader 
                                        accept='image/*'
                                        id='image'
                                        name='image'
                                        randomizeFilename
                                        onUploadStart={handleUploadStart}
                                        onUploadError={handleUploadError}
                                        onUploadSuccess={handleUploadSuccess}
                                        onProgress={handleProgress}
                                    />
                                </Box> */}
                                <Box mt={7} w={'100%'}>
                                    <Box flexDirection={'row'} justifyContent='space-between'>
                                        <Text>Título</Text>
                                        <Text fontSize={'12px'}  mr={8} color={'gray.500'}>Max {dataTeam.name.length}/50</Text>
                                    </Box>
                                    <input type='text' value={dataTeam.name} className='create-admin-input' onChange={t => setDataTeam({...dataTeam,name:t.target.value})}/>
                                    <Text mt={5}>Descriptición</Text>
                                    <textarea value={dataTeam.description} type='text' className='create-admin-input create-admin-textarea' onChange={t => setDataTeam({...dataTeam,description:t.target.value})}/>
                                </Box>
                                
                            </Box>
                            <Box width={'100%'} mt={7} alignItems={'center'} flexDirection={'column'}  justifyContent='space-between'>
                                <Box>
                                
                                    <Box flexDirection={'row'} justifyContent='space-between' mb={5} >
                                        <Box w={'40%'}>
                                            <Text>Precio</Text>
                                            <input value={dataTeam.cost} bg={'#E6E6E7'} borderWidth={0} defaultValue={0} min={0} type='number'className='create-admin-input' onChange={t => setDataTeam({...dataTeam,cost:t.target.value})} />
                                        </Box>
                                        <Box w={'40%'}>
                                            <Text>Max de Personas</Text>
                                            <input bg={'#E6E6E7'} borderWidth={0} defaultValue={0} min={0} type='number'  className='create-admin-input' onChange={t => setDataTeam({...dataTeam,maxPeople:t.target.value})} />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Text>Fecha</Text>
                                        <input
                                            type="datetime-local"
                                            className='create-admin-input'
                                            id="dateTimeInput"
                                            value={dateSelect}
                                            onChange={handleDateTimeChange}
                                        />

                                        <Text mt={5}>Categorías</Text>
                                        <Box>
                                            <Select borderColor={dataTeam.categoryColor} bg='#E6E6E7' selectedValue={dataTeam.category} defaultValue={dataTeam.category} borderRadius={6}  placeholder="Seleccionar categoria" placeholderTextColor='black' fontSize='14px' onValueChange={v => setDataTeam({...dataTeam,category:v})} >
                                                {dbCategoryIcons.map(category => {
                                                    return(
                                                        <Select.Item  key={category.code} borderRadius='10px' label={category.name} value={category.code}  />

                                                    );
                                                })}
                                            </Select>
                                        </Box>
                                    </Box>  
                                </Box>
                                <Box bg={'#E6E6E7'} justifyContent='space-between' mt={10} p={5} borderRadius={10} width={'100%'} h={'300px'}>
                                    <Box>
                                        <Text bold fontSize={'2xl'}>Ubicación</Text>
                                        <Text  fontSize={'lg'} mt={2}>Detalles:</Text>
                                        <Text  fontSize={'ms'} mt={2}>País: {dataTeam.countryLocation}</Text>
                                        <Text  fontSize={'ms'} mt={2}>Provincia: {dataTeam.state}</Text>
                                        <Text  fontSize={'ms'} mt={2}>Ciudad: {dataTeam.city}</Text>
                                        <Text  fontSize={'ms'} mt={2}>Dirección: {dataTeam.address}</Text>
                                    </Box>
                                    <Button onPress={() => setModalMap(true)}>
                                        <Text bold color={'white'}>Elegir</Text>
                                    </Button>
                                    
                                </Box>
                                <Box w={'100%'} mt={'20px'} h={'300px'} justifyContent={'space-between'}>
                                    <Box w={'100%'} p={5}  bg={'#E6E6E7'} h={'260px'} borderRadius={10}>
                                        <Text bold fontSize={'2xl'} mb={2}>Contactos</Text>
                                        <Text ml={1}>Instagram</Text>
                                        <Input placeholder='link del grupo' mb={2} bg={'gray.100'} value={dataTeam.instagram} borderRadius={6} onChangeText={t => setDataTeam({...dataTeam,instagram:t})}/>
                                        <Text ml={1}>Web</Text>
                                        <Input placeholder='link del grupo'  bg={'gray.100'} value={dataTeam.discord} borderRadius={6} onChangeText={t => setDataTeam({...dataTeam,discord:t})}/>

                                    </Box>
                                   
                                </Box>

                            </Box>
                            
                        </Box>
                    </Box>
                </Modal.Body>
                <Modal.Footer flexDirection={'row-reverse'} >
                    <Button bg={'#FE6D01'} width={'60%'}  borderRadius={8} shadow={1} onPress={() => uploadTeam()}>
                        <Text bold color='white'>Actualizar 🔝</Text>
                    </Button>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
     );
}
 
export default ModalEditTeam;