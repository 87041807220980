import React from 'react';
import Phones from '../sources/phones.png';
import Phones2 from '../sources/phones2.png';
import AppStore from '../sources/appStore.png';
import PlayStore from '../sources/playStore.png';
import Logo from '../sources/logo2.svg';
import IconFacebook from '../sources/facebook.svg';
import IconInstagram from '../sources/instagram.svg';
import IconLinkedin from '../sources/linkedin.svg';
import IconTiktok from '../sources/tiktok.svg';
import IconTwitter from '../sources/twitter.svg';
import { Button } from 'native-base';

const Section1 = () => {
    return ( 
        <div className='home-container'>
            <img src={Phones} className='home-picture1' alt='App pictures'/>
            <img src={Phones2} className='home-picture2' alt='App pictures'/>
            <div className='home-title'>
                <img src={Logo}  alt='App pictures'/>
                <p>Todos los planes, en una sola app!</p>
                <h1>Conocé nuevas experiencias <br/> Hacé nuevos amigos.</h1>
                {/* <Butt className='home-button'>
                    <p>Unirme a TEAMS</p>
                </Butt> */}
            </div>
            <div className='home-redes'>
                {/* <img src={IconLinkedin}  alt='icon social red'/> */}
                <a target='_blank' href='https://www.instagram.com/teamssocialapp/?hl=en#'><img src={IconInstagram}   alt='icon social red'/></a>
                {/* <img src={IconFacebook}  alt='icon social red'/> */}
                {/* <img src={IconTwitter}  alt='icon social red'/> */}
                {/* <img src={IconTiktok}  alt='icon social red'/> */}
            </div>
            <div className='home-stores'>
                {/* <a href='/'>
                    <img src={PlayStore}  alt='icon social red'/>
                </a> */}
                <a href='https://apps.apple.com/ar/app/teams-app/id6456361741'>
                    <img src={AppStore}  alt='icon social red'/>
                </a>
            </div>
        </div>
     );
}
 
export default Section1;
