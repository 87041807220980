import { Box, Button, Heading, Image, Input,Text } from 'native-base';
import React, { useContext, useState } from 'react'
import Logo from '../../sources/logo2.svg';
import { FirebaseContext } from '../../db/firebase';
import { Navigate } from 'react-router-dom';

const LoginAdmin = () => {
    const [email, setEmail] = useState('second');
    const [password, setPassword] = useState('');
    const {firebase} = useContext(FirebaseContext);
    const [redirectToDashboard, setRedirectToDashboard] = useState(false);


  
    const signIn = async () =>{
        
        
        await firebase.auth.signInWithEmailAndPassword(email, password).then(() =>{
            console.log('entramos con exito');
            localStorage.setItem("userEmail", email);

            setRedirectToDashboard(true); // Establecer el estado para redirigir

        }).catch(err =>{
            console.log(err)
            return;
        });
        
      

    }
    if (redirectToDashboard) {
        return <Navigate to="/dashboardAdmin" />;
      }
    return ( 
        <Box w='100%' height='100vh' bg='amber.600' justifyContent='center' alignItems='center'>
            <Box bg={'white'} w={'30%'} p={7} borderRadius={10} shadow={2}>
                <Box flexDirection='row' justifyContent={'space-between'} >
                    <Heading mb={5}>Login</Heading>
                    <Image src={Logo} w={'77%'} h={'50px'} alt='Logo'/>

                </Box>
                <Box>
                    <Text>Email</Text>
                    <Input type='text' onChangeText={t => setEmail(t)}/>
                </Box>
                <Box mt={5}>
                    <Text>Password</Text>
                    <Input type='password' onChangeText={t => setPassword(t)}/>
                </Box>
                <Button alignSelf={'flex-end'} bg='orange.500' mt={5} onPress={() => signIn()}>
                    <Text>Entrar</Text>
                </Button>
            </Box>
        </Box> 
    );
}
 
export default LoginAdmin;