import React, { useContext, useEffect, useState } from 'react'
import { Image, Spinner,Box } from 'native-base';
import { FirebaseContext } from '../../db/firebase';

const TeamView = ({urlImg}) => {
    
    
    return ( 
        <Box>
            <Image source={urlImg} mx={5} shadow={2} mt={2} size={'xl'} borderRadius={10} alt='Team'/>
        </Box>
     );
}
 
export default TeamView;