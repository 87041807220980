import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../db/firebase';
import { Box, Heading, ScrollView,Text,Image, Select, Pressable} from 'native-base';
import TeamView from '../../components/ui/TeamView';
import IconClock from '../../sources/icons/clock.svg';
import IconTick from '../../sources/icons/tick.svg';
import IconReject from '../../sources/icons/rejected.svg';
import dbCategoryIcons from '../../db/locales/dbCategoryIcons';
import dbCountries from '../../db/locales/dbCountries';
const Moderation = () => {
    const {firebase} = useContext(FirebaseContext)
    const [data, setData] = useState({});
    const [country, setCountry] = useState('teamsArgentina');
    const [filter, setFilter] = useState('Revision')
    useEffect(() => {
        firebase.db.collection(country).onSnapshot(driveSnapshot);
        function driveSnapshot(snapshot) {
            let db = snapshot.docs.map( meet =>{
                return{
                    ...meet.data()
                }
            });
            setData(db)
        }
        console.log('buscando en: '+ country);
    }, [country]);

    return ( 
        <Box w='100%' >
            <Box flexDirection={'row'} mx={2} my={5} justifyContent={'space-between'} >
                <Heading>Moderation</Heading>
                <Select maxDropdownItems={3}   bg='#F5F5F5' borderRadius={10}   borderWidth={2} accessibilityLabel="Seleccionar pais" placeholderTextColor='black' fontSize='18px' placeholder={'Argentina'}  onValueChange={v => setCountry(v)} >
                    {dbCountries.map(country => {
                        return(
                            <Select.Item bold key={country.abbreviation} pt={'30px'} shadow={2}  my={2} borderWidth={'2px'} borderRadius='10px'  label={country.name + ' ' + country.emoji} value={'teams'+country.name.replace(/\s/g, '')} />
                        );
                    })}
                </Select>
                
            </Box>
            <Box flexDirection='row' justifyContent={'space-around'} alignItems='center' pl={2} py={2} >
                <Pressable borderRadius={'20px'} px={5} flexDirection='row' alignItems='center' pl={2} py={2} shadow={filter === 'Revision' ? 'none' : 2} bg='gray.300'  onPress={() => setFilter('Revision')}>
                    <Image src={IconClock} w={'50px'} h={'50px'}  alt='logo'/>
                    <Heading color={'yellow.300'}>Revision</Heading>
                </Pressable>
                <Pressable borderRadius={'20px'} px={5} flexDirection='row' alignItems='center' pl={2} py={2} shadow={filter === 'Approved' ? 'none' : 2} bg='gray.300' onPress={() => setFilter('Approved')}>
                    <Image src={IconTick} w={'50px'} h={'50px'}  alt='logo'/>
                    <Heading color={'#00b341'}>Approved</Heading>
                </Pressable>
                    
                <Pressable borderRadius={'20px'} px={5} flexDirection='row' alignItems='center' pl={2} py={2} shadow={filter === 'Rejected' ? 'none' : 2} bg='gray.300' onPress={() => setFilter('Rejected')}>
                    <Image src={IconReject} w={'50px'} h={'50px'}  alt='logo'/>
                    <Heading color={'#ff2825'}>Rejected</Heading>
                </Pressable>
            </Box>
            <Box horizontal={true} py={5} display={filter === 'Revision' ? 'flex' : 'none'}> 
                {data.length > 0 ? data.map( team =>{
                    if(team.moderateStatus != 'wait'){
                        return null;
                    }

                    return(
                        <Box key={team.id}>
                            <TeamView imgUrl={team.imgUrl}/>
                        </Box>
                    );
                }):(
                    <Box>
                        <Text> Todo moderado '{':)'}'</Text>

                    </Box>
                )}
                
            </Box>
            
            <ScrollView  h={'75vh'} w='100%'  py={5} display={filter === 'Approved' ? 'flex' : 'none'}> 
                <div className='container-teams-moderation'>
                    {data.length > 0 ? data.map( team =>{
                        if(team.moderateStatus != 'approved'){
                            return null;
                        }
                        return(
                            <Box key={team.id}>
                                <TeamView imgUrl={team.imgUrl}/>
                            </Box>
                        );
                    }):(
                        <Box>
                            <Text> Ningun Aprobado </Text>

                        </Box>
                    )}
                </div>
                
            </ScrollView>
           
            <ScrollView horizontal={true} py={5} display={filter === 'Rejected' ? 'flex' : 'none'}> 
                {data.length > 0 ? data.map( team =>{
                    if(team.moderateStatus != 'rejected'){
                        return null;
                    }
                    return(
                        <Box key={team.id}>
                            <TeamView imgUrl={team.imgUrl}/>
                        </Box>
                    );
                }):(
                    <Box>
                        <Text> Ningun Rechazado '{':)'}'</Text>

                    </Box>
                )}
                
            </ScrollView>
        </Box>
     );
}
 
export default Moderation;