import React, { useContext, useState } from 'react';
import { Button, Heading, Input, Modal, Text, Box, Pressable,useToast, Image,Checkbox } from 'native-base';
import PadlockIcon from '../../sources/icons/padlockIcon.svg';
import EyeIcon from '../../sources/icons/eyeIcon.svg';
import EyeClosedIcon from '../../sources/icons/eyeClosedIcon.svg';
import UserIcon from '../../sources/icons/userIcon.svg';
import FileUploader from 'react-firebase-file-uploader';
import PhotoDefault from '../../sources/photoProfileDefault.png';
import firebase from '../../db/firebase';
import ModalLoader from './ModalLoader';
import MapSelect from '../ui/MapSelect';
import UserContext from '../../contexts/user/userContext';
// import { Avatar as AvatarProfile } from 'react-avatar';


const ModalRegister = ({showModal,setShowModal}) => {
    const toast = useToast();
    const [mail, setMail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [terms, setTerms] = useState(false);
    const [locationInfo, setLocationInfo] = useState({
        location:{latitude:'',longitude:''},
        countryLocation:'',
        state:'',
        address:'',
        city:'',
    })
    //StateMap
    const [modalMap, setModalMap] = useState(false)
    const [upload, setUpload] = useState(false);
    const [progress, setProgress] = useState(0);
    const [loader, setLoader] = useState(false);
    const {uploadImageToStorage} = useContext(UserContext);
    const WarningToast = (message,bgColor) => {
        toast.show({
            render: () => {
                return(
                    <Box bg={bgColor} px={'25px'} py='15px' rounded={20} shadow={2}>
                        <Text color='white' fontSize='20px' bold >{message}</Text>
                    </Box>
                );
            },
            placement: 'top'
        })
        return;
    }
    const registerNewUser = async () => {
        if(mail === '' || name === '' || password === '' || rePassword === ''){
            WarningToast('Debes llenar todos los campos','red.600');
            return;
        }
        else if(password != rePassword){
            WarningToast('Las contraseñas no coinciden','red.600');     
            return;
        }
        else if(locationInfo.countryLocation === ''){
            WarningToast('La ubicación no reconoce ningun pais','red.600');     
        }
        
        else if(terms === false){
            WarningToast('Debes aceptar los terminos','red.600');     
            return;
        }
        setLoader(true);
        const imgRoute = `photoUsers/${mail}/photoUser.jpg`;
        await firebase.auth.createUserWithEmailAndPassword(mail,password)
                        .then( async (userCredential) => {
                            userCredential.user.updateProfile({
                                displayName: name,
                                photoURL: imgRoute,
                                country:locationInfo.country,
                                state:locationInfo.state,
                                address:locationInfo.address,
                                location:{latitude:locationInfo.location.latitude,longitude:locationInfo.location.longitude}
                            })
                            await firebase.db.collection('userData').doc(userCredential.user.uid).set({name:name,email:mail,photoProfile:imgRoute,country:locationInfo.country,state:locationInfo.state,address:locationInfo.address,location:{latitude:locationInfo.location.latitude,longitude:locationInfo.location.longitude}}).catch(err => console.log(err.code))
                            await uploadImageToStorage(PhotoDefault,imgRoute);
                            console.log('Se creo el usuario');
                            setShowModal(false);
                            
                        }).catch(error => {
                            if (error.code === 'auth/email-already-in-use') {
                                WarningToast('Email en uso','red.600');
                                setLoader(false);
                                return;
                            }else if(error.code === 'auth/weak-password'){
                                WarningToast('Contraseña muy debil','red.600');
                                setLoader(false);
                                return;
                            }else if(error.code === 'auth/invalid-email'){
                                WarningToast('Email invalido','red.600');
                                setLoader(false);
                                return;
                            }else if (error.code === undefined) {
                                WarningToast('Hubo un error, intentelo mas tarde','red.600');
                                setLoader(false);
                                return;
                            }
                        });
        setLoader(false);
    } 
   
   
    return ( 
        <Modal isOpen={showModal}>
            <ModalLoader showModal={loader} />
            <MapSelect showModal={modalMap} setShowModal={setModalMap} dataTeam={locationInfo} setChanges={setLocationInfo}/>

            <Modal.Content>
                <Modal.Header flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Heading>Register</Heading>
                    <Button bg='none' onHoverIn>
                        <Text onPress={() => setShowModal(false)}>Cerrar</Text>
                    </Button>
                </Modal.Header>
                <Modal.Body py={10}>
                    {/* <Box alignItems='center'>
                        <Image source={urlImg} borderRadius={10} w={'50px'} h={'50px'} mb={2} alt='Photo default'/>
                        <FileUploader 
                            accept='image/*'
                            id='image'
                            name='image'
                            randomizeFilename
                            storageRef={('../../sources/photoUpdate/')}
                            onUploadStart={handleUploadStart}
                            onUploadError={handleUploadError}
                            onUploadSuccess={handleUploadSuccess}
                            onProgress={handleProgress}
                        />
                    </Box> */}
                    {/* <AvatarProfile color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])} name="Wim Mostmans" /> */}
                    <Input my='10px' fontSize='20px'  type='text'   placeholder="Juan" borderWidth={0} borderRadius={0} borderBottomWidth={1} borderColor='black' focusOutlineColor={'orange.500'} 
                        onChangeText={t => setName(t)}
                        InputLeftElement={<Image src={UserIcon} height={25} width={25} stroke='#000'/>}

                    />
                    <Input my='10px' fontSize='20px' autoCapitalize="none" type='email'   placeholder="email@ejemplo.com" borderWidth={0} borderRadius={0} borderBottomWidth={1} borderColor='black' focusOutlineColor={'orange.500'} 
                        onChangeText={t => setMail(t)}
                        InputLeftElement={<Image src={UserIcon} height={25} width={25} stroke='#000'/>}

                    />
                    <Input my='10px' fontSize='20px' autoCapitalize="none"   placeholder="*******" borderWidth={0} borderRadius={0} borderBottomWidth={1} borderColor='black' focusOutlineColor={'orange.500'} 
                        type={showPassword ? "text" : "password"}
                        onChangeText={t => setPassword(t)}
                        InputLeftElement={<Image src={PadlockIcon} height={25} width={25} stroke='#000'/>}

                        InputRightElement={
                            <Pressable py={2}  px={3} h={'100%'}  onPress={() => setShowPassword(!showPassword)}>
                                {showPassword ? (
                                    <Image src={EyeIcon} height={25} width={25} stroke='#000'/>
                                ) :(
                                    <Image src={EyeClosedIcon} height={25} width={25} stroke='#000'/>
                                )}
                            </Pressable>
                        }
                    
                    />
                    <Input my='10px' fontSize='20px' autoCapitalize="none"   placeholder="*******" borderWidth={0} borderRadius={0} borderBottomWidth={1} borderColor='black' focusOutlineColor={'orange.500'} 
                        type={showPassword ? "text" : "password"}
                        onChangeText={t => setRePassword(t)}
                        InputLeftElement={<Image src={PadlockIcon} height={25} width={25} stroke='#000'/>}
                    />
                    <Box>
                        <Button onPress={() => setModalMap(true)}>Elegir Zona</Button>
                        <Text>{locationInfo.address}</Text>
                    </Box>
                     <Box flexDirection='row' alignItems='flex-start' w='100%' px='1' my={5}>
                        <Checkbox outlineColor='orange.500' value={terms} onChange={() => setTerms(!terms)}>
                            <Text ml={1}>Acepto los <Text bold onPress={() => window.open("/terms", "_blank")}>términos y condiciones</Text></Text>
                        </Checkbox>
                    </Box>
                </Modal.Body>
                <Modal.Footer justifyContent={'space-between'} alignItems='center' flexDirection='row-reverse'>
                    <Button bg='orange.500' onHoverIn onPress={() => registerNewUser()}>
                        <Text color={'white'}>Registrarse</Text>
                    </Button>
                    
                </Modal.Footer>
            </Modal.Content>
        </Modal>
     );
}
 
export default ModalRegister;