import React, { useEffect } from 'react';
import { NativeBaseProvider } from "native-base";
import './css/index.css';
import firebase, {FirebaseContext} from './db/firebase';
import UserState from './contexts/user/userState';
import TeamsState from './contexts/teams/teamsState';
//Router
import Router from './Router';
import RouterMobile from './RouterMobile';


function App() {
  const esMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  

  return (
    <FirebaseContext.Provider
      value={{
        firebase
      }}
    >
      <UserState>
        <TeamsState>
          <NativeBaseProvider>
            {esMobile?(
              <RouterMobile/>
            ):(
              <Router/>
            )}
          </NativeBaseProvider>
        </TeamsState>  
      </UserState>
    </FirebaseContext.Provider>
  );
}

export default App;
