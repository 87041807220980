import React, { useContext, useEffect, useState } from 'react';
import { Box, Heading } from 'native-base';
import Nav from '../components/ui/Nav';
import UserContext from '../contexts/user/userContext';
import firebase from '../db/firebase';

const MyTeams = () => {
    const {checkSession,session,emailVerified} = useContext(UserContext);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        firebase.auth.onAuthStateChanged(user =>{
            if (user) {
                if(!user.emailVerified) {
                    window.location.href = "/";
                    return;
                }
                return;
            }else{
                window.location.href = "/";
            }
        })
        
      
    }, []);

    
    
    return ( 
        <Box>
            <Nav/>
        </Box>
     );
}
 
export default MyTeams;