import React from 'react'
// Router
import {
    BrowserRouter,
    Routes ,
    Route,
} from "react-router-dom";
//Pages
import Section1 from './pages/Section1';
import Team from './pages/Team';

const RouterMobile = () => {
    return ( 
        <BrowserRouter>
            <Routes>
                <Route path='/' exact element={<Section1/>}/>
                <Route path='/team/:country/:id' exact element={<Team mobile={true}/>}/>
            </Routes>
        </BrowserRouter>
     );
}
 
export default RouterMobile;