import React, { useState,useContext } from 'react';
import { Box, Image,Pressable,Text } from 'native-base';
import Logo from '../../sources/logo.svg';
import IconHome from '../../sources/icons/home.svg';
import IconHomeBlack from '../../sources/icons/homeBlack.svg';
import IconShield from '../../sources/icons/shield.svg';
import IconShieldBlack from '../../sources/icons/shieldBlack.svg';
import IconGroup from '../../sources/icons/group.svg';
import IconGroupBlack from '../../sources/icons/groupBlack.svg';
import IconCreate from '../../sources/icons/more.svg';
import IconCreateBlack from '../../sources/icons/moreBlack.svg';
import IconLogout from '../../sources/icons/logout.svg';
import IconIdeas from '../../sources/icons/ideas.svg';
import IconIdeasBlack from '../../sources/icons/ideasBlack.svg';
import IconPoint from '../../sources/icons/point.svg';
import IconPointBlack from '../../sources/icons/pointBlack.svg';
import HomeAdmin from '../../pages/admin/HomeAdmin';
import CreateAdmin from '../../pages/admin/CreateAdmin';
import Moderation from '../../pages/admin/Moderation';
import MyTeams from '../../pages/admin/MyTeams';
import IdeasAdmin from '../../pages/admin/IdeasAdmin';
import Points from '../../pages/admin/Points';
import UserContext from '../../contexts/user/userContext';
import ModalConfirmation from '../modals/ModalConfirmation';

const NavAdmin = ({page,setPage,setComponent}) => {
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const {userPhoto,signOut,} = useContext(UserContext);

    return ( 
        <Box bg={'#FF6D00'} w={'20%'} h={'100vh'} alignItems={'center'} shadow={2} pt={10} borderColor={'gray.100'} borderRightWidth={1}>
            <ModalConfirmation showModal={modalConfirmation} setShowModal={setModalConfirmation} functionModal={signOut} text={'¿Estas seguro que deseas cerra sesión?'} heading={'Cerrar Sesión'} buttonR={'Cerrar'} buttonL={'Volver'}/>

            <Pressable w={'90%'} h={'50px'} onPress={() => window.location.href = "/"}>
                <Image src={Logo} w={'90%'} h={'50px'} alt='logo' />

            </Pressable>
            <Text bold color={'white'} textAlign={'end'} w={'78%'}>Admin</Text>
            <Box mt={16} width={'100%'} h={'50%'} justifyContent={'space-between'}>
                <div className={page === 1 ?'.nav-isActive':'nav-dashboard-admin'}>
                    <Pressable flexDirection={'row'} alignItems={'center'} mb={8}  onPress={() => {setComponent(<HomeAdmin/>);setPage(1)}}>
                        <Image src={page === 1 ? IconHomeBlack :IconHome} w={'50px'} h={'50px'} mx={2} alt='logo'/>
                        <Text bold color={page === 1 ?'gray.700':'white'} fontSize={28}>Home</Text>
                    </Pressable>
                </div>
                <div className={page === 2 ?'.nav-isActive':'nav-dashboard-admin'}>
                    <Pressable flexDirection={'row'} alignItems={'center'} mb={8} onPress={() => {setComponent(<CreateAdmin/>);setPage(2)}}>
                        <Image src={page === 2 ? IconCreateBlack :IconCreate} w={'50px'} h={'50px'} mx={2} alt='logo'/>
                        <Text bold color={page === 2 ?'gray.700':'white'} fontSize={28}>New Team</Text>
                    </Pressable>
                </div>
                <div className={page === 3 ?'.nav-isActive':'nav-dashboard-admin'}>
                    <Pressable flexDirection={'row'} alignItems={'center'} mb={8} onPress={() => {setComponent(<Moderation/>);setPage(3)}}>
                        <Image src={page === 3 ? IconShieldBlack : IconShield} w={'45px'} h={'45px'} mx={2} alt='logo'/>
                        <Text bold color={page === 3 ?'gray.700':'white'} fontSize={28}>Moderation</Text>
                    </Pressable>
                </div>
                <div className={page === 4 ?'.nav-isActive':'nav-dashboard-admin'}>
                    <Pressable flexDirection={'row'} alignItems={'center'} mb={8} onPress={() => {setComponent(<MyTeams/>);setPage(4)}}>
                        <Image src={page === 4 ? IconGroupBlack : IconGroup} w={'45px'} h={'45px'} mx={2} alt='logo'/>
                        <Text bold color={page === 4 ?'gray.700':'white'} fontSize={28}>My Teams</Text>
                    </Pressable>
                </div>
                <div className={page === 5 ?'.nav-isActive':'nav-dashboard-admin'}>
                    <Pressable flexDirection={'row'} alignItems={'center'} mb={8} onPress={() => {setComponent(<IdeasAdmin/>);setPage(5)}}>
                        <Image src={page === 5 ? IconIdeasBlack : IconIdeas} w={'45px'} h={'45px'} mx={2} alt='logo'/>
                        <Text bold color={page === 5 ?'gray.700':'white'} ml={2} fontSize={28}>Ideas</Text>
                    </Pressable>
                </div>
                <div className={page === 6 ?'.nav-isActive':'nav-dashboard-admin'}>
                    <Pressable flexDirection={'row'} alignItems={'center'} mb={8} onPress={() => {setComponent(<Points/>);setPage(6)}}>
                        <Image src={page === 6 ? IconPointBlack : IconPoint} w={'45px'} h={'45px'} mx={2} alt='logo'/>
                        <Text bold color={page === 6 ?'gray.700':'white'} ml={2} fontSize={28}>Points</Text>
                    </Pressable>
                </div>
            </Box>
            <Box   mt={'120px'} alignItems={'center'} >
                <Image mb={2}  src={userPhoto} alt='Photo user' size={'xs'} borderRadius={'50%'} borderColor='white' borderStyle='solid' borderWidth='2px'  />
                <Pressable flexDirection={'row'} onPress={() => setModalConfirmation(true)}>
                    <Text bold color={'red.800'}>Sign off</Text>
                    <Image src={IconLogout} w={'20px'} h={'20px'} mx={2} alt='logo'/>
                </Pressable>

            </Box>
        </Box>
     );
}
 
export default NavAdmin;