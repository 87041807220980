import React, { useContext, useEffect, useState } from 'react';
import { Image, Text, Box,Button, Pressable, Divider  } from 'native-base';
import ModalLogin from '../modals/ModalLogin';
import ModalRegister from '../modals/ModalRegister';

//SOURCES
import Logo from '../../sources/logo3.svg';
import UserContext from '../../contexts/user/userContext';
// import firebase from '../../db/firebase';
import ModalConfirmation from '../modals/ModalConfirmation';
import IconMenu from '../../sources/icons/menu.svg';
import IconLogo from '../../sources/icons/logo-hands.svg';
// import IconMailCheck from '../../sources/icons/emailCheckTrue.svg';
// import IconMailSend from '../../sources/icons/emailSend.svg';
// import IconUser from '../../sources/icons/userIcon.svg';
import ModalQr from '../modals/ModalQr';
const Nav = () => {
    const {checkSession,session,userPhoto,signOut,emailVerified,admin} = useContext(UserContext);
    const [modalLogin, setModalLogin] = useState(false);
    const [modalRegister, setModalRegister] = useState(false);
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const [modalQr, setModalQr] = useState(false);
    const [loader, setLoader] = useState(false);
    // const [page, setPages] = useState('home');
    const [menu, setMenu] = useState(false);
    useEffect(() => {
        const check = async () => {
            
            setLoader(true);
            await checkSession();
    
            setLoader(false);
        }

       check();
        
    }, []);

    
   
    
    
    



    return ( 
        <header className='header-container'>
            <ModalLogin showModal={modalLogin} setShowModal={setModalLogin} setRegisterModal={setModalRegister}/>
            <ModalRegister showModal={modalRegister} setShowModal={setModalRegister}/>
            <ModalConfirmation showModal={modalConfirmation} setShowModal={setModalConfirmation} functionModal={signOut} text={'¿Estas seguro que deseas cerra sesión?'} heading={'Cerrar Sesión'} buttonR={'Cerrar'} buttonL={'Volver'}/>
            <ModalQr showModal={modalQr} setShowModal={setModalQr}/>
            <Box flexDirection={'row'} alignItems={'center'}>
                <Pressable onPress={() => window.location.href = "/"}>
                    <img src={Logo} alt='logo'/>
                </Pressable>
                <Button ml={6} mt={1} shadow={2} borderRadius={'26px'} w={'170px'} bg={'#FF8A1F'} onPress={() => setModalQr(true)}>
                    <Text color='white' bold  fontSize={'16px'}>Descargar APP</Text>
                </Button>
            </Box>
            {session?(
                <Box flexDirection='row'  alignItems='center' position={'relative'}>
                        
                    <Pressable onPress={() => setMenu(!menu)} borderRadius={'50px'} flexDirection={'row'} className='nav-container'  shadow={menu?'none':2} py={'5px'} pr={'5px'} pl={4}  alignItems='center' mx={10} borderWidth={menu?0:1} borderStyle={'solid'} borderColor={'gray.400'} >
                        
                        <Image  src={IconMenu} alt='Photo user' mr={4} w={'35px'} h={'22px'} />
                        <Image  display={loader?'none':'flex'}src={userPhoto} alt='Photo user' size={'45px'} borderRadius={'50%'} borderColor='white' borderStyle='solid' borderWidth='2px'  />
                    </Pressable>
                    <Box display={menu?'':'none'} bg={'white'} shadow={2} borderRadius={'27px'} p={4} mt={2} position={'absolute'} right={5} top={'100%'} borderWidth={1} borderStyle={'solid'} borderColor={'gray.400'} >
                        {admin?(
                            <Pressable alignItems='center' flexDirection='row' bg='none' onPress={() => window.location.href = "/dashboardAdmin"}>
                                {/* <Image mr={2} src={IconUser} alt='Admin' size={'2xs'} /> */}
                                <Text color='yellow.300'  fontSize={'14px'} mr={2} bold>Admin</Text>
                            </Pressable>
                        ):null}
                        <Pressable my={2} flexDirection={'row'} alignItems={'center'} onPress={() => window.location.href = "/my-teams"}>
                            <Image  src={IconLogo} alt='My teams' w={'25px'} h={'20px'} mr={1} />
                            <Text fontSize={'14px'}  mr={5}>Mis Teams</Text>
                        </Pressable>
                        <Divider size={.4} my={2} w={'100%'}/>
                        <Text  fontSize={'14px'} mt={2} onPress={() => setModalConfirmation(true)} mr={5}>Cerrar Sesión</Text>

                    </Box>

                </Box>

            ):(
                <Box className='nav-container' display={loader?'none':'flex'}  flexDirection='row'alignItems='center' >
                    <Text display={loader?'none':'flex'} fontSize={'18px'} onPress={() => setModalLogin(true)}>Sign In</Text>
                    <Text display={loader?'none':'flex'} mx={10} fontSize={'18px'} onPress={() => setModalRegister(true)}>Sign Up</Text>
                </Box>
            )}

        </header>
     );
}
 
export default Nav;