import React, { useEffect, useState,Alert } from 'react'
import { Box, Button, Heading,Input,Text, Pressable,useToast, Image, ScrollView } from 'native-base';
import MapSelect from '../../components/ui/MapSelect';
import firebase from '../../db/firebase';
import Loader from '../../components/ui/Loader';
import ModalConfirmation from '../../components/modals/ModalConfirmation';
import MapView from '../../components/ui/MapView';
import IconPoint from '../../sources/icons/iconPoint.svg';
import PointImg from '../../sources/icons/pointBlack.svg';


const Points = () => {
    const [data, setData] = useState({
        name:'',
        location:{latitude:0,longitude:0},
        countryLocation:'',
        state:'',
        city:'',
        address:''
    });
    const toast = useToast();
    const [loader, setLoader] = useState(false);
    const [points, setPoints] = useState([]);
    const [form, setForm] = useState(false);
    const [modalMap, setModalMap] = useState(false);
    const [selectLocation, setSelectLocation] = useState({lat:-31.429323108750776,lng:-64.18752323652237})
    const sendToast = (message,color) => {
        toast.show({
            render: () => {
                return(
                    <Box bg={color} px={'25px'} py='15px' rounded={16} shadow={2}>
                        <Text color='white' fontSize='16px' bold >{message}</Text>
                    </Box>
                );
            },
            placement: 'top'
        })
    }
    const uploadPoint = async() => {
        setLoader(true);
        if(data.name === '') sendToast('Falta el nombre','red.500');
        else if(data.location.longitude == 0 || data.location.longitude == 0) sendToast('Error en las cordenadas','red.500');
        else if(data.countryLocation === '') sendToast('Falta el País','red.500');
        else if(data.state === '') sendToast('Falta la Provincia/Estado','red.500');
        else {
            await firebase.db.collection('points').doc().set(data).then(()=>{
                sendToast('Subido con exito','green.500');
                setData({name:'',location:{},city:'',countryLocation:'',state:'',address:''})
                setForm(false);
            }).catch(err => sendToast(err.code));
        }
        setLoader(false);

    }
    const getPoints = async() => {
        firebase.db.collection('points').onSnapshot(driveSnapshot);
        function driveSnapshot(snapshot) {
            let db = snapshot.docs.map( point =>{
                return{
                    ...point.data(),
                    id: point.id,
                }
            });
            setPoints(db);
        }
    }
    const deletePoint = async id =>{
        setLoader(true);
        const result = window.confirm('¿Estás seguro de realizar esta acción?');
        if (result) await firebase.db.collection('points').doc(id).delete().catch(err => console.log(err));
        setLoader(false);
    }


    useEffect(() => {
      getPoints();
    }, [])

    
    return ( 
        <Box w={'100%'} h={'100%'}>
            <MapSelect showModal={modalMap} setShowModal={setModalMap} dataTeam={data} setChanges={setData}/>
            <Loader showModal={loader}/>
            <Box w={'100%'} shadow={2}>
                <MapView height={'300px'} selectLocation={selectLocation} points={points}/>
            </Box>
            <Box my={2} ml={4} flexDirection={'row'} alignSelf={'flex-start'} alignItems={'center'}>
                <Image source={PointImg} width={'50px'} stroke='#000'  h='50px' alt='icon'/>
                <Heading ml={2} color={'black'}>Points
                    <Text fontSize={'16px'} bold={200}> {' ('+ points.length +')'}</Text>
                </Heading>
            </Box>
            <ScrollView w={'100%'}  p={4}>
                <div className='pointers-container'>
                {points.length > 0? points.map(point => {
                    return(
                        <Pressable onPress={() => setSelectLocation({lat:point.location.latitude,lng:point.location.longitude})} mb={4} flexDirection={'row'} alignItems='center' justifyContent={'space-between'} w={'250px'} key={point.id} p={2} borderRadius={'30px'} shadow={2} bg={'white'}>
                            <Box flexDirection='row' alignItems='center' w={'70%'}>
                                <Image source={IconPoint} width={'40px'}  h='40px' alt='icon'/>
                                <Text ml={2} numberOfLines={1} maxW={'100%'}>{point.name}</Text>
                            </Box>
                            <Button zIndex={3} h={'20px'} variant={'ghost'} p={0} onPress={() => deletePoint(point.id)}>
                                <Text color={'red.500'} bold fontSize={'20px'} >X</Text>
                            </Button>
                        </Pressable>
                    );
                }):(
                    <Heading w={'100%'} color={'black'} textAlign={'center'} ml={'50%'}>No Hay Points :{')'}</Heading>
                )}
                </div>
            </ScrollView>
            <Box bg="rgba(0, 0, 0, 0.5)"   h={'100%'}  w={'100%'} position={'absolute'}  alignItems={'center'} justifyContent={'center'} display={form ? 'flex':'none'}>
                <Box bg={'white'} p={8} w={'600px'} borderRadius={'20px'} mb={2} shadow={2}>
                    <Text bold color={'gray.500'} onPress={() => setForm(false)}  position={'absolute'} top={2} right={5} >X</Text>
                    <Box>
                        <Text bold fontSize='18px'>Name</Text>
                        <Input placeholder='Title Idea' value={data.name} onChangeText={t => setData({...data,name:t})}/>
                    </Box>
                    <Box mt={2}>
                        <Text bold fontSize='18px'>Location</Text>
                        <Box>
                            <Text>Country:{data.countryLocation}</Text>
                            <Text>State:{data.state}</Text>
                            <Text>City:{data.city}</Text>
                            <Text>Address:{data.address}</Text>
                        </Box>
                        <Button mt={4} w={'30%'} onPress={() => setModalMap(true)}>
                            <Text bold color={'white'}>Location Select</Text>
                        </Button>
                    </Box>
                    
                    <Button w={'20%'} mt={2}  alignSelf={'flex-end'} bg='orange.500' onPress={() => uploadPoint()}>
                        <Text color={'white'} bold>Upload Point</Text>
                    </Button>
                </Box>
            </Box>
            <Button display={form ? 'none':'flex'}   h={'60px'} borderRadius={'20px'}  alignSelf={'flex-end'} bg='orange.500' position={'absolute'} bottom={6} right={6} onPress={() => setForm(true)}>
                <Text color={'white'} fontSize={'30px'} bold>New Point +</Text>
            </Button>

        </Box>
     );
}
 
export default Points;