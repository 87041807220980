import React from 'react';
import { Heading, Modal, Box, Image, Pressable } from 'native-base';

import IosImg from '../../sources/appStore.png';
import IconCross from '../../sources/icons/cross.svg';
import IosQr from '../../sources/qr-code-ios.png';
import { Linking } from 'react-native-web';
const ModalQr = ({showModal,setShowModal}) => {
    return ( 
        <Modal isOpen={showModal} size={'2xl'} mt={10} alignSelf={'center'} borderRadius={'50px'}>
            <Modal.Content borderRadius={'40px'}>
                <Modal.Body bg={'#FFE4B7'} >
                    <Heading mt={5} fontSize={'26px'} mb={4} textAlign={'center'}>Descargá la app!</Heading>
                    <Box my={5} flexDirection={'row'} alignItems={'center'}>
                        <Box mx={10} bg={'#FF881C'} p={6} borderRadius={'30px'} alignItems={'center'}>
                            <Box mb={2} bg={'white'} p={4} borderRadius={'20px'}>
                                <Image source={IosQr} size={'170px'} alt='ios'/>
                            </Box>
                            <Pressable onPress={() => Linking.openURL("https://apps.apple.com/ar/app/teams-app/id6456361741")}>
                                <Image source={IosImg} w={'160px'} h={'60px'} alt='ios'/>
                            </Pressable>
                        </Box>
                    </Box>
                    <Pressable position={'absolute'} top={3} right={3} onPress={() => setShowModal(false)} >
                        <Image source={IconCross} size={'xs'} alt='cross'/>
                    </Pressable>
                </Modal.Body>
            </Modal.Content>
        </Modal>
     );
}
 
export default ModalQr;