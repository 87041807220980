import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Image, Input, Select, Text, useToast } from 'native-base';
import dbCategoryIcons from '../../db/locales/dbCategoryIcons';
import FileUploader from 'react-firebase-file-uploader';
import { FirebaseContext } from '../../db/firebase';
import MapSelect from '../../components/ui/MapSelect';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../../components/ui/Loader';


const CreateAdmin = () => {

    const {firebase} = useContext(FirebaseContext);
    const uniqueId = uuidv4();
    const [loader, setLoader] = useState(false);
    //StateMap
    const [modalMap, setModalMap] = useState(false)
    //Date
    const [dateSelect, setDateSelect] = useState(Date.now())
    //State images
    const [upload, setUpload] = useState(false);
    const [progress, setProgress] = useState(0);
    const [urlImg, setUrlImg] = useState('https://firebasestorage.googleapis.com/v0/b/teams-643cb.appspot.com/o/source%2FphotoDefault.png?alt=media&token=59b895d6-b969-4e25-a2c3-8b0e09613a39');
    //New Team
    const [dataTeam, setDataTeam] = useState({
        id:uniqueId,
        name:'',
        description:'',
        likes:[],
        assistants:[],
        imgUrl:'',
        dateCreation: Date.now(),
        dateMeet: new Date(),
        time:'',
        cost: 0,
        // idUser:userId,
        whatsapp:'',
        instagram:'',
        discord:'',
        maxPeople:1,
        category:'',
        categoryColor:'#C4C4C4',
        subcategory:'',
        location:{latitude:0,longitude:0},
        countryLocation:'',
        state:'',
        address:'',
        city:'',
        creator:'Team Admins',
        creatorType:'admin',
        moderateStatus:'approved'
    });
    const handleUploadStart = () =>{
        setProgress(0);
        setUpload(true);
        setLoader(true);

    };
    const handleUploadError = err =>{
        setUpload(true);
        console.log(err);
        setLoader(false);


    };
    const handleUploadSuccess = async name =>{
        setProgress(100);
        setUpload(false);
        setLoader(false);
        
        const url = await firebase.storage.ref('teams/teamsCreate/').child(name).getDownloadURL();

        setDataTeam({...dataTeam,imgUrl:`teams/teamsCreate/${name}`});
        setUrlImg(url);
    };
    const handleProgress = n => {
        setProgress(n);
        console.log(n);
    };
    //Toast
    const toast = useToast();
    const sendToast = (message,color) => {
        toast.show({
            render: () => {
                return(
                    <Box bg={color} px={'25px'} py='15px' rounded={16} shadow={2}>
                        <Text color='white' fontSize='16px' bold >{message}</Text>
                    </Box>
                );
            },
            placement: 'top'
        })
    }
    //Change color category
    useEffect(() => {
        if (dataTeam.category) {
            let arr = dbCategoryIcons.filter( c => c.code === dataTeam.category);
            setDataTeam({...dataTeam,categoryColor:arr[0].color})
            
        }
    }, [dataTeam.category]);
    //Date
    const handleDateTimeChange = (event) => {
        const inputValue = event.target.value;
        setDateSelect(inputValue)
        if (inputValue) {
          const timestamp = new Date(inputValue).getTime() / 1000; // Convertir a segundos
          const timestampValue = { seconds: timestamp, nanoseconds: 0 };
          setDataTeam({...dataTeam,dateMeet:timestampValue})
        }
      };
    //Upload Team
    const uploadTeam = async () => {

        if (dataTeam.name === '' || dataTeam.description === '' || dataTeam.category === ''|| dataTeam.imgUrl === '') {
            sendToast('Completa todo los campos','#E51E3B');
            return;
        }
        if (dataTeam.countryLocation === '') {
            sendToast('La ubicaciòn no pertenece a ningun pais','#E51E3B');
            return;
        }
        if (dataTeam.discord === '' && dataTeam.whatsapp === '' && dataTeam.instagram === '' ) {
            sendToast('Necesitas un grupo como contacto','#E51E3B'); 
            return;
        }
        setLoader(true);

        try {
            await firebase.db.collection('teams'+ dataTeam.countryLocation.replace(/\s/g, "")).doc(dataTeam.id).set(dataTeam).catch(err => console.log(err));
            //PUT ID IN DATA USER CREATOR
            await firebase.db.collection('teamsAdmin').doc(dataTeam.id).set({id:dataTeam.id,country:dataTeam.countryLocation}).catch(err => console.log(err));
            //reset
            setLoader(false);
            console.log('Creado con exito');
        } catch (error) {
            console.error(error);
            setLoader(false);
            return;
        }
        
        window.location.reload();
        setTimeout(() => {
            sendToast('Team Creado con éxito','green.500'); 
            
        }, 2000);
    }
    return ( 
        <Box width='100%' height={'100%'} alignItems={'center'} justifyContent={'center'}>
            <Loader showModal={loader}/>
            <MapSelect showModal={modalMap} setShowModal={setModalMap} dataTeam={dataTeam} setChanges={setDataTeam}/>
            <Box bgColor={'gray.100'} justifyContent={'space-around'} borderRadius={10} borderWidth={1} borderColor={'black.100'} w={'80%'} h={'80%'} shadow={2} p={5}>
                <Box flexDirection={'row'} w={'100%'} justifyContent={'space-between'}>
                    <Box w={'35%'} alignItems='center'>
                        <Image source={{uri: urlImg}} borderRadius={10} w={'100%'} h={'250px'} mb={2} alt='Event'/>
                        <FileUploader 
                            accept='image/*'
                            id='image'
                            name='image'
                            randomizeFilename
                            storageRef={firebase.storage.ref('teams/teamsCreate/')}
                            onUploadStart={handleUploadStart}
                            onUploadError={handleUploadError}
                            onUploadSuccess={handleUploadSuccess}
                            onProgress={handleProgress}
                        />
                    </Box>
                    <Box w={'61%'}>
                        <Box flexDirection={'row'} justifyContent='space-between'>
                            <Text>Título</Text>
                            <Text fontSize={'12px'}  mr={8} color={'gray.500'}>Max {dataTeam.name.length}/50</Text>
                        </Box>
                        <input type='text' className='create-admin-input' onChange={t => setDataTeam({...dataTeam,name:t.target.value})}/>
                        <Text mt={5}>Descriptición</Text>
                        <textarea  type='text' className='create-admin-input create-admin-textarea' onChange={t => setDataTeam({...dataTeam,description:t.target.value})}/>
                    </Box>
                    
                </Box>
                <Box width={'100%'} flexDirection={'row'}  justifyContent='space-between'>
                    <Box w={'35%'} alignSelf={''} mt={10}>
                     
                        <Box flexDirection={'row'} justifyContent='space-between' mb={5} >
                            <Box width={'30%'} >
                                <Text>Precio</Text>
                                <input bg={'#E6E6E7'} borderWidth={0} defaultValue={0} min={0} type='number'className='create-admin-input' onChange={t => setDataTeam({...dataTeam,cost:t.target.value})} />
                            </Box>
                            <Box width={'40%'} >
                                <Text>Max de Personas</Text>
                                <input bg={'#E6E6E7'} borderWidth={0} defaultValue={0} min={0} type='number'  className='create-admin-input' onChange={t => setDataTeam({...dataTeam,maxPeople:t.target.value})} />
                            </Box>
                        </Box>
                        <Box>
                            <Text>Fecha</Text>
                            <input
                                type="datetime-local"
                                className='create-admin-input'
                                id="dateTimeInput"
                                value={dateSelect}
                                onChange={handleDateTimeChange}
                            />

                            <Text mt={5}>Categorías</Text>
                            <Box>
                                <Select borderColor={dataTeam.categoryColor} bg='#E6E6E7' selectedValue={dataTeam.category} defaultValue={dataTeam.category} borderRadius={6}  placeholder="Seleccionar categoria" placeholderTextColor='black' fontSize='14px' onValueChange={v => setDataTeam({...dataTeam,category:v})} >
                                    {dbCategoryIcons.map(category => {
                                        return(
                                            <Select.Item  key={category.code} borderRadius='10px' label={category.name} value={category.code}  />

                                        );
                                    })}
                                </Select>
                            </Box>
                        </Box>  
                    </Box>
                    <Box w={'28%'} bg={'#E6E6E7'} justifyContent='space-between' p={5}  borderRadius={10} h={'300px'}>
                        <Box>
                            <Text bold fontSize={'2xl'}>Ubicación</Text>
                            <Text  fontSize={'lg'} mt={2}>Detalles:</Text>
                            <Text  fontSize={'ms'} mt={2}>País: {dataTeam.countryLocation}</Text>
                            <Text  fontSize={'ms'} mt={2}>Provincia: {dataTeam.state}</Text>
                            <Text  fontSize={'ms'} mt={2}>Ciudad: {dataTeam.city}</Text>
                            <Text  fontSize={'ms'} mt={2}>Dirección: {dataTeam.address}</Text>
                        </Box>
                        <Button onPress={() => setModalMap(true)}>
                            <Text bold color={'white'}>Elegir</Text>
                        </Button>
                        
                    </Box>
                    <Box w={'30%'}  h={'300px'} justifyContent={'space-between'}>
                        <Box w={'100%'} p={5}  bg={'#E6E6E7'} h={'260px'} borderRadius={10}>
                            <Text bold fontSize={'2xl'} mb={2}>Contactos</Text>
                            {/* <Text ml={1}>Whatsapp</Text>
                            <Input placeholder='link del grupo' mb={2} bg={'gray.100'} borderRadius={6} onChangeText={t => setDataTeam({...dataTeam,whatsapp:t})}/> */}
                            <Text ml={1}>Instagram</Text>
                            <Input placeholder='link del grupo' mb={2} bg={'gray.100'} borderRadius={6} onChangeText={t => setDataTeam({...dataTeam,instagram:t})}/>
                            <Text ml={1}>Web</Text>
                            <Input placeholder='link de la web'  bg={'gray.100'} borderRadius={6} onChangeText={t => setDataTeam({...dataTeam,discord:t})}/>

                        </Box>
                        <Button bg={'#FE6D01'} width={'80%'} alignSelf={'end'} borderRadius={8} mt={2} shadow={1} onPress={() => uploadTeam()}>
                            <Text bold color='white'>Crear +</Text>
                        </Button>
                    </Box>

                </Box>
                
            </Box>
        </Box>
    );
}
 
export default CreateAdmin;