import { Button, Heading, Modal, Text } from 'native-base';
import React from 'react';


const ModalConfirmation = ({showModal,setShowModal,text,heading,buttonR,buttonL,functionModal}) => {
    return ( 
        <Modal isOpen={showModal}>
            <Modal.Content>
                <Modal.Header>
                    <Heading>{heading}</Heading>
                </Modal.Header>
                <Modal.Body>
                    <Text>{text}</Text>
                </Modal.Body>
                <Modal.Footer flexDirection={'row'} alignItems='center' justifyContent='space-between'>
                    <Button background='none' onPress={() => setShowModal(false)}><Text>{buttonL}</Text></Button>
                    <Button bg='orange.500' onPress={() =>{ functionModal(); setShowModal(false)}}><Text color='white'>{buttonR}</Text></Button>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
     );
}
 
export default ModalConfirmation;