
const dbCategoryIcons = [
    {name:'Eventos',img:require('../../sources/iconsCategory/pinkRed.png'),code:'Sociales y Fiestas', pin:require('../../sources/pins/pinPinkRed.png'),color:'#E83265',index:0},
    {name:'Mateadas',img:require('../../sources/iconsCategory/pink.png'),code:'Mateadas', pin:require('../../sources/pins/pinPink.png'),color:'#F29095',index:1},
    {name:'Outdoor',img:require('../../sources/iconsCategory/green.png'),code:'Outdoor', pin:require('../../sources/pins/pinGreen.png'),color:'#5D8F69',index:2},
    {name:'Viajes',img:require('../../sources/iconsCategory/greenYellow.png'),code:'Viajes', pin:require('../../sources/pins/pinGreenYellow.png'),color:'#7DBF7A',index:3},    
    {name:'Deportes',img:require('../../sources/iconsCategory/blue.png'),code:'Deportes y Fitness', pin:require('../../sources/pins/pinBlue.png'),color:'#314A72',index:4},    
    {name:'Arte',img:require('../../sources/iconsCategory/yellow.png'),code:'Arte y Cultura', pin:require('../../sources/pins/pinYellow.png'),color:'#FDCE7E',index:5},    
    {name:'Trabajo',img:require('../../sources/iconsCategory/red.png'),code:'Trabajo Profesión', pin:require('../../sources/pins/pinRed.png'),color:'#E51E3B',index:6},    
    {name:'Hobbies',img:require('../../sources/iconsCategory/orange.png'),code:'Hobbies', pin:require('../../sources/pins/pinOrange.png'),color:'#F39153',index:7},    
    {name:'Charlas',img:require('../../sources/iconsCategory/greenBlue.png'),code:'Eventos y Charlas', pin:require('../../sources/pins/pinBlueGreen.png'),color:'#138384',index:8},    
    {name:'Voluntariados',img:require('../../sources/iconsCategory/greenBlack.png'),code:'Voluntariados', pin:require('../../sources/pins/pinGreenBlack.png'),color:'#006B6B',index:9},    
]
export default dbCategoryIcons;
