import React, { useState, useEffect, useContext } from 'react';
import { Pressable,Image,Box,Text } from 'native-base';
import TeamView from '../../components/ui/TeamView';
import IconDelete from '../../sources/icons/rejected.svg';
import AlertDialogDelete from '../../components/ui/AlertDialogDelete';
import { FirebaseContext } from '../../db/firebase';


const TeamViewInfo = ({team,setLoader}) => {
    const [alert, setAlert] = useState(false);
    const [date, setDate] = useState(new Date())  
    const [isActive, setIsActive] = useState(false);
    const [loader3, setLoader3] = useState(false);
    const [urlImg, setUrlImg] = useState('')
    const {firebase} = useContext(FirebaseContext)
   
    useEffect(() => {
        setLoader3(true);
        const storageRef = firebase.storage.ref();
        const imageRef = storageRef.child(team.imgUrl);

        imageRef.getDownloadURL().then((url) => {
        setUrlImg(url);
        });
        setTimeout(() => {
            setLoader3(false);
            
        }, 1000);

    }, [team])
    
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    useEffect(() => {
      
        const date = new Date(team.dateMeet.seconds * 1000 + Math.round(team.dateMeet.nanoseconds / 1000000));
        const dateNow = new Date();
        if (date < dateNow) {
            setIsActive(false)
        } else {
            setIsActive(true)
        }
    }, [team.dateMeet])

    useEffect(() => {
        let res = new Date(team.dateMeet.seconds * 1000 + team.dateMeet.nanoseconds / 1000000);
        setDate(res);
    }, [team.dataMeet]);
    
    
    
    
    return ( 
        <>
        {!loader3 ? (
                <Box key={team.id} alignSelf={'center'} flexDirection='row' mb={10} w='100%' bg={'red'}>
                <AlertDialogDelete showAlert={alert} setShowAlert={setAlert} team={team} setLoader={setLoader}/>
                <Box>
                    {urlImg?(
                        <Image source={{uri: urlImg}} mx={5} shadow={2} mt={2} size={'xl'} borderRadius={10} alt='Team'/>

                    ):null}
                </Box>
                <Box justifyContent={'space-between'} w='50%'>
                    <Box>
                        <Text bold  mt={5}>{team.name}</Text>
                        <Text fontSize='14px' ml={1} > {date.getDate()} {months[date.getMonth()]}</Text>
                    </Box>
                    <Text bold color={isActive?'green.600':'red.500'}>{isActive?'Activo':'Terminado'}</Text>
                </Box>
                <Pressable position={'absolute'} top={-1} left={2} bg='white' borderRadius={50} onPress={() => setAlert(true)}>
                    <Image src={IconDelete} w={'35px'} h={'35px'}  alt='Cross'/>
                </Pressable>
                </Box>

            ):(
                <span class="loader-4">hola</span>
            ) }
        </>
     );
}
 
export default TeamViewInfo;