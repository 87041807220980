import React, { useEffect,useState } from 'react';
import { Box } from 'native-base';

//COMPONENTS
import Nav from '../components/ui/Nav';
import Section1 from './Section1';

const Home = () => {

    return ( 
        <Box>
            <Nav/>
            <Section1/>
        </Box>
     );
}
 
export default Home;