import React, { useContext, useState } from 'react';
import { Button, Heading, Input, Modal, Text, Box, Pressable, Image, useToast } from 'native-base';
import PadlockIcon from '../../sources/icons/padlockIcon.svg';
import EyeIcon from '../../sources/icons/eyeIcon.svg';
import EyeClosedIcon from '../../sources/icons/eyeClosedIcon.svg';
import UserIcon from '../../sources/icons/userIcon.svg';
import UserContext from '../../contexts/user/userContext';
import ModalLoader from './ModalLoader';
import firebase from '../../db/firebase';
const ModalLogin = ({showModal,setShowModal,setRegisterModal}) => {
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loader, setLoader] = useState(false);
    const {setUserData,checkSession,userData} = useContext(UserContext);
    const toast = useToast();

    const loginUser = async () => {
        setLoader(true);
        await firebase.auth.signInWithEmailAndPassword(mail,password).then(async (userCredential) => {
               await setUserData({...userData,session:true});
               checkSession()
               console.log('login exitoso');
               setShowModal(false);
               setMail('');
               setPassword('');
               toast.show({
                render: () => {
                    return(
                        <Box bg='green.500' px={5} py={2} borderRadius={10}>
                            <Text fontSize='lg' color='white' bold>Bienvenido {userCredential.user.displayName}!!</Text>
                        </Box>
                    );
                },
                placement: 'top'
            })
           })
           .catch(error => {
                if(error.code === 'auth/invalid-email'){
                    console.log('error con el email');
                    toast.show({
                        render: () => {
                            return(
                                <Box bg='red.500' px={5} py={2} borderRadius={10}>
                                    <Text fontSize='lg' color='white' bold>Email invalido</Text>
                                </Box>
                            );
                        },
                        placement: 'top'
                    })
                }else if(error.code === 'auth/wrong-password'){
                    console.log('error con la contraseña');
                    toast.show({
                        render: () => {
                            return(
                                <Box bg='red.500' px={5} py={2} borderRadius={10}>
                                    <Text fontSize='lg' color='white' bold>Contraseña incorrecta</Text>
                                </Box>
                            );
                        },
                        placement: 'top'
                    })
                }else if(error.code === 'auth/user-not-found'){
                    console.log('error con el usuario');
                    toast.show({
                        render: () => {
                            return(
                                <Box bg='red.500' px={5} py={2} borderRadius={10}>
                                    <Text fontSize='lg' color='white' bold>Usuario no encontrado</Text>
                                </Box>
                            );
                        },
                        placement: 'top'
                    })
                }
           }) 

        setLoader(false);
    }

    
    return ( 
        <Modal isOpen={showModal}>
            <ModalLoader showModal={loader}/>
            <Modal.Content>
                <Modal.Header flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Heading>Login</Heading>
                    <Button bg='none' onHoverIn>
                        <Text onPress={() => setShowModal(false)}>Cerrar</Text>
                    </Button>
                </Modal.Header>
                <Modal.Body py={10}>
                    <Input my='10px' fontSize='20px' autoCapitalize="none" type='email'   placeholder="email@ejemplo.com" borderWidth={0} borderRadius={0} borderBottomWidth={1} borderColor='black' focusOutlineColor={'orange.500'} 
                        onChangeText={t => setMail(t)}
                        InputLeftElement={<Image src={UserIcon} height={25} width={25} stroke='#000'/>}

                    />
                    <Input my='10px' fontSize='20px' autoCapitalize="none"   placeholder="*******" borderWidth={0} borderRadius={0} borderBottomWidth={1} borderColor='black' focusOutlineColor={'orange.500'} 
                        type={showPassword ? "text" : "password"}
                        onChangeText={t => setPassword(t)}
                        InputLeftElement={<Image src={PadlockIcon} height={25} width={25} stroke='#000'/>}

                        InputRightElement={
                            <Pressable py={2}  px={3} h={'100%'}  onPress={() => setShowPassword(!showPassword)}>
                                {showPassword ? (
                                    <Image src={EyeIcon} height={25} width={25} stroke='#000'/>
                                ) :(
                                    <Image src={EyeClosedIcon} height={25} width={25} stroke='#000'/>
                                )}
                            </Pressable>
                        }
                    
                    />
                </Modal.Body>
                <Modal.Footer justifyContent={'space-between'} alignItems='center' flexDirection='row-reverse'>
                    <Button bg='orange.500' onHoverIn onPress={() => loginUser()}>
                        <Text color={'white'}>Entrar</Text>
                    </Button>
                    <Button bg='none' onHoverIn>
                        <Text color={'orange.500'} onPress={() => {setShowModal(false);setRegisterModal(true)}}>Registrarse</Text>
                    </Button>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
     );
}
 
export default ModalLogin;