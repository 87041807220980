// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCuMcnh3DA31hcq9yYpKoJrcR50hJfbpns",
  authDomain: "teams-643cb.firebaseapp.com",
  databaseURL: "https://teams-643cb-default-rtdb.firebaseio.com",
  projectId: "teams-643cb",
  storageBucket: "teams-643cb.appspot.com",
  messagingSenderId: "1061410407398",
  appId: "1:1061410407398:web:0d67e2328f7207f9934c27",
  measurementId: "G-TT9FWDWKN4"
};

export default firebaseConfig;