import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Heading,Input,Text, TextArea, useToast,Center, Image } from 'native-base';
import { v4 as uuidv4 } from 'uuid';
import firebase from '../../db/firebase';
import ModalLoader from '../../components/modals/ModalLoader';
import UserContext from '../../contexts/user/userContext';
import IconIdeas from '../../sources/icons/ideasBlack.svg';


const IdeasAdmin = () => {
    const [admin, setAdmin] = useState('admin');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [ideasData, setIdeasData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [form, setForm] = useState(false);
    const {userName,checkSession,userEmail} = useContext(UserContext);
    const uniqueId = uuidv4();
    const toast = useToast();

    useEffect(() => {
            
        setLoader(true);
        checkSession();
        getIdeas();
        setLoader(false);
        console.log(ideasData)
    }, []);

    const getIdeas= async () => {
        firebase.db.collection('ideas').where('creatorType', '==', 'admin').onSnapshot(driveSnapshot);
        function driveSnapshot(snapshot) {
            let db = snapshot.docs.map( idea =>{
                return{
                    ...idea.data(),
                    id: idea.id,
                }
            });
            setIdeasData(db);
        }
    }

    const uploadIdeas = async () => {
        setLoader(true);
        if (title === ''  || description === '') {
            setLoader(false);
            toast.show({
                render: () => {
                    return(
                        <Box bg='red.500' px={5} py={2} borderRadius={10}>
                            <Text fontSize='lg' color='white' bold>Llena todos los campos</Text>
                        </Box>
                    );
                },
                placement: 'top'
            })
            return;
        }
        const today = new Date();
        await firebase.db.collection('ideas').doc().set({
            title:title,
            description:description,
            creator:userEmail,
            creatorName:userName,
            creatorType:'admin',
            dateCreation:today.toISOString(), 

        }).then(() =>{
            toast.show({
                render: () => {
                    return(
                        <Box bg='green.500' px={5} py={2} borderRadius={10}>
                            <Text fontSize='lg' color='white' bold>Successfully created idea</Text>
                        </Box>
                    );
                },
                placement: 'top'
            });
            setForm(false);
        }).catch(err =>{
            toast.show({
                render: () => {
                    return(
                        <Box bg='red.500' px={5} py={2} borderRadius={10}>
                            <Text fontSize='lg' color='white' bold>{err.code}</Text>
                        </Box>
                    );
                },
                placement: 'top'
            })
        });
        setLoader(false);




    }

    const deleteIdea = async id =>{
        setLoader(true);
        await firebase.db.collection('ideas').doc(id).delete().catch(err => console.log(err));
        setLoader(false);
    }
        

    return ( 
        <Box width={'100%'} justifyContent={'space-between'} height={'100%'} bg='orange.200'>
            <ModalLoader showModal={loader}/>
            <Box h='20%' justifyContent='center' alignItems='center' p='20px'>
                <Box flexDirection={'row'} alignItems='center'>
                    <Image source={IconIdeas} height={10} width={10}/>
                    <Heading ml={2}>Ideas</Heading>
                </Box>
                <Box flexDirection='row' mt={10}>
                    <Text mx={2}>Admins</Text>
                    <Text mx={2}>Users</Text>
                </Box>
               
            </Box>
            <div className='container-ideas' >
                {ideasData.length > 0 ? ideasData.map(idea => {
                    return(
                        
                        <Box key={idea.title} bg={'white'} justifyContent='space-between' shadow={2} maxH={'200px'} minH={'200px'} w='200px' borderRadius={'25px'} mt={6} pt={6} pb={4} px={4}>
                            <Box>
                                <Text fontSize={'18px'}mb={2} bold textAlign='center'>{idea.title}</Text>
                                <Text fontSize={'12px'} color={'gray.600'}>{idea.description}</Text>
                            </Box>
                            <Text fontSize={'12px'} color={'gray.400'} alignSelf='flex-end'>by {idea.creatorName}</Text>
                            <Button onPress={() => deleteIdea(idea.id)} bg={'red.500'} borderRadius={'50px'} px={'10px'} py={1} position={'absolute'} top={-2} right={-2}>
                                <Text color='white'>X</Text>
                            </Button>
                        </Box>
                    );
                }):(
                    <Box w={'100%'} justifyContent='center' ml={'300%'}>
                        <Heading textAlign='center' color={'gray.500'}>There are no ideas :{'('}</Heading>
                    </Box>
                )}

            </div>
            <Box bg="rgba(0, 0, 0, 0.5)"   h={'100%'}  w={'100%'} position={'absolute'}  alignItems={'center'} justifyContent={'center'} display={form ? 'flex':'none'}>
                <Box bg={'white'} p={8} w={'600px'} borderRadius={'20px'} mb={2} shadow={2}>
                    <Text bold color={'gray.500'} onPress={() => setForm(false)}  position={'absolute'} top={2} right={5} >X</Text>

                    <Box>
                        <Text>Title</Text>
                        <Input placeholder='Title Idea' value={title} onChangeText={t => setTitle(t)}/>
                    </Box>
                    <Box mt={2}>
                        <Text>Description</Text>
                        <TextArea placeholder='Write details' value={description} onChangeText={t => setDescription(t)}/>
                    </Box>
                    <Text color={'gray.500'}>Admin: {userName}</Text>
                    <Button w={'40%'} mt={2}  alignSelf={'flex-end'} bg='orange.500' onPress={() => uploadIdeas()}>
                        <Text color={'white'} bold>Upload idea</Text>
                    </Button>
                </Box>
            </Box>
            <Button display={form ? 'none':'flex'}  w={'60px'} h={'60px'} borderRadius={'50px'}  alignSelf={'flex-end'} bg='orange.500' position={'absolute'} bottom={6} right={6} onPress={() => setForm(true)}>
                <Text color={'white'} fontSize={'30px'} bold>+</Text>
            </Button>
        </Box>
     );
}
 
export default IdeasAdmin;