const dbCountries = [
    {name: 'Afghanistan', abbreviation: 'AF', emoji: '🇦🇫'},
    {name: 'Albania', abbreviation: 'AL', emoji: '🇦🇱'},
    {name: 'Algeria', abbreviation: 'DZ', emoji: '🇩🇿'},
    {name: 'Andorra', abbreviation: 'AD', emoji: '🇦🇩'},
    {name: 'Angola', abbreviation: 'AO', emoji: '🇦🇴'},
    {name: 'Antigua and Barbuda', abbreviation: 'AG', emoji: '🇦🇬'},
    {name: 'Argentina', abbreviation: 'AR', emoji: '🇦🇷'},
    {name: 'Armenia', abbreviation: 'AM', emoji: '🇦🇲'},
    {name: 'Australia', abbreviation: 'AU', emoji: '🇦🇺'},
    {name: 'Austria', abbreviation: 'AT', emoji: '🇦🇹'},
    {name: 'Azerbaijan', abbreviation: 'AZ', emoji: '🇦🇿'},
    {name: 'Bahamas', abbreviation: 'BS', emoji: '🇧🇸'},
    {name: 'Bahrain', abbreviation: 'BH', emoji: '🇧🇭'},
    {name: 'Bangladesh', abbreviation: 'BD', emoji: '🇧🇩'},
    {name: 'Barbados', abbreviation: 'BB', emoji: '🇧🇧'},
    {name: 'Belarus', abbreviation: 'BY', emoji: '🇧🇾'},
    {name: 'Belgium', abbreviation: 'BE', emoji: '🇧🇪'},
    {name: 'Belize', abbreviation: 'BZ', emoji: '🇧🇿'},
    {name: 'Benin', abbreviation: 'BJ', emoji: '🇧🇯'},
    {name: 'Bhutan', abbreviation: 'BT', emoji: '🇧🇹'},
    {name: 'Bolivia', abbreviation: 'BO', emoji: '🇧🇴'},
    {name: 'Bosnia and Herzegovina', abbreviation: 'BA', emoji: '🇧🇦'},
    {name: 'Botswana', abbreviation: 'BW', emoji: '🇧🇼'},
    {name: 'Brazil', abbreviation: 'BR', emoji: '🇧🇷'},
    {name: 'Brunei Darussalam', abbreviation: 'BN', emoji: '🇧🇳'},
    {name: 'Bulgaria', abbreviation: 'BG', emoji: '🇧🇬'},
    {name: 'Burkina Faso', abbreviation: 'BF', emoji: '🇧🇫'},
    {name: 'Burundi', abbreviation: 'BI', emoji: '🇧🇮'},
    {name: 'Cabo Verde', abbreviation: 'CV', emoji: '🇨🇻'},
    {name: 'Cambodia', abbreviation: 'KH', emoji: '🇰🇭'},
    {name: 'Cameroon', abbreviation: 'CM', emoji: '🇨🇲'},
    {name: 'Canada', abbreviation: 'CA', emoji: '🇨🇦'},
    {name: 'Central African Republic', abbreviation: 'CF', emoji: '🇨🇫'},
    {name: 'Chad', abbreviation: 'TD', emoji: '🇹🇩'},
    {name: 'Chile', abbreviation: 'CL', emoji: '🇨🇱'},
    {name: 'China', abbreviation: 'CN', emoji: '🇨🇳'},
    {name: 'Colombia', abbreviation: 'CO', emoji: '🇨🇴'},
    {name: 'Comoros', abbreviation: 'KM', emoji: '🇰🇲'},
    {name: 'Congo', abbreviation: 'CG', emoji: '🇨🇬'},
    {name: 'Costa Rica', abbreviation: 'CR', emoji: '🇨🇷'},
    {name: 'Croatia', abbreviation: 'HR', emoji: '🇭🇷'},
    {name: 'Cuba', abbreviation: 'CU', emoji: '🇨🇺'},
    {name: 'Cyprus', abbreviation: 'CY', emoji: '🇨🇾'},
    {name: 'Czech Republic', abbreviation: 'CZ', emoji: '🇨🇿'},
    {name: 'Democratic Republic of the Congo', abbreviation: 'CD', emoji: '🇨🇩'},
    {name: 'Denmark', abbreviation: 'DK', emoji: '🇩🇰'},
    {name: 'Djibouti', abbreviation: 'DJ', emoji: '🇩🇯'},
    {name: 'Dominica', abbreviation: 'DM', emoji: '🇩🇲'},
    {name: 'Dominican Republic', abbreviation: 'DO', emoji: '🇩🇴'},
    {name: 'Ecuador', abbreviation: 'EC', emoji: '🇪🇨'},
    {name: 'Egypt', abbreviation: 'EG', emoji: '🇪🇬'},
    {name: 'El Salvador', abbreviation: 'SV', emoji: '🇸🇻'},
    {name: 'Equatorial Guinea', abbreviation: 'GQ', emoji: '🇬🇶'},
    {name: 'Eritrea', abbreviation: 'ER', emoji: '🇪🇷'},
    {name: 'Estonia', abbreviation: 'EE', emoji: '🇪🇪'},
    {name: 'Eswatini', abbreviation: 'SZ', emoji: '🇸🇿'},
    {name: 'Ethiopia', abbreviation: 'ET', emoji: '🇪🇹'},
    {name: 'Fiji', abbreviation: 'FJ', emoji: '🇫🇯'},
    {name: 'Finland', abbreviation: 'FI', emoji: '🇫🇮'},
    {name: 'France', abbreviation: 'FR', emoji: '🇫🇷'},
    {name: 'Gabon', abbreviation: 'GA', emoji: '🇬🇦'},
    {name: 'Gambia', abbreviation: 'GM', emoji: '🇬🇲'},
    {name: 'Georgia', abbreviation: 'GE', emoji: '🇬🇪'},
    {name: 'Germany', abbreviation: 'DE', emoji: '🇩🇪'},
    {name: 'Ghana', abbreviation: 'GH', emoji: '🇬🇭'},
    {name: 'Greece', abbreviation: 'GR', emoji: '🇬🇷'},
    {name: 'Grenada', abbreviation: 'GD', emoji: '🇬🇩'},
    {name: 'Guatemala', abbreviation: 'GT', emoji: '🇬🇹'},
    {name: 'Guinea', abbreviation: 'GN', emoji: '🇬🇳'},
    {name: 'Guinea-Bissau', abbreviation: 'GW', emoji: '🇬🇼'},
    {name: 'Guyana', abbreviation: 'GY', emoji: '🇬🇾'},
    {name: 'Haiti', abbreviation: 'HT', emoji: '🇭🇹'},
    {name: 'Honduras', abbreviation: 'HN', emoji: '🇭🇳'},
    {name: 'Hungary', abbreviation: 'HU', emoji: '🇭🇺'},
    {name: 'Iceland', abbreviation: 'IS', emoji: '🇮🇸'},
    {name: 'India', abbreviation: 'IN', emoji: '🇮🇳'},
    {name: 'Indonesia', abbreviation: 'ID', emoji: '🇮🇩'},
    {name: 'Iran', abbreviation: 'IR', emoji: '🇮🇷'},
    {name: 'Iraq', abbreviation: 'IQ', emoji: '🇮🇶'},
    {name: 'Ireland', abbreviation: 'IE', emoji: '🇮🇪'},
    {name: 'Israel', abbreviation: 'IL', emoji: '🇮🇱'},
    {name: 'Italy', abbreviation: 'IT', emoji: '🇮🇹'},
    {name: 'Jamaica', abbreviation: 'JM', emoji: '🇯🇲'},
    {name: 'Japan', abbreviation: 'JP', emoji: '🇯🇵'},
    {name: 'Jordan', abbreviation: 'JO', emoji: '🇯🇴'},
    {name: 'Kazakhstan', abbreviation: 'KZ', emoji: '🇰🇿'},
    {name: 'Kenya', abbreviation: 'KE', emoji: '🇰🇪'},
    {name: 'Kiribati', abbreviation: 'KI', emoji: '🇰🇮'},
    {name: 'Kuwait', abbreviation: 'KW', emoji: '🇰🇼'},
    {name: 'Kyrgyzstan', abbreviation: 'KG', emoji: '🇰🇬'},
    {name: 'Laos', abbreviation: 'LA', emoji: '🇱🇦'},
    {name: 'Latvia', abbreviation: 'LV', emoji: '🇱🇻'},
    {name: 'Lebanon', abbreviation: 'LB', emoji: '🇱🇧'},
    {name: 'Lesotho', abbreviation: 'LS', emoji: '🇱🇸'},
    {name: 'Liberia', abbreviation: 'LR', emoji: '🇱🇷'},
    {name: 'Libya', abbreviation: 'LY', emoji: '🇱🇾'},
    {name: 'Liechtenstein', abbreviation: 'LI', emoji: '🇱🇮'},
    {name: 'Lithuania', abbreviation: 'LT', emoji: '🇱🇹'},
    {name: 'Luxembourg', abbreviation: 'LU', emoji: '🇱🇺'},
    {name: 'Madagascar', abbreviation: 'MG', emoji: '🇲🇬'},
    {name: 'Malawi', abbreviation: 'MW', emoji: '🇲🇼'},
    {name: 'Malaysia', abbreviation: 'MY', emoji: '🇲🇾'},
    {name: 'Maldives', abbreviation: 'MV', emoji: '🇲🇻'},
    {name: 'Mali', abbreviation: 'ML', emoji: '🇲🇱'},
    {name: 'Malta', abbreviation: 'MT', emoji: '🇲🇹'},
    {name: 'Marshall Islands', abbreviation: 'MH', emoji: '🇲🇭'},
    {name: 'Mauritania', abbreviation: 'MR', emoji: '🇲🇷'},
    {name: 'Mauritius', abbreviation: 'MU', emoji: '🇲🇺'},
    {name: 'Mexico', abbreviation: 'MX', emoji: '🇲🇽'},
    {name: 'Micronesia', abbreviation: 'FM', emoji: '🇫🇲'},
    {name: 'Moldova', abbreviation: 'MD', emoji: '🇲🇩'},
    {name: 'Monaco', abbreviation: 'MC', emoji: '🇲🇨'},
    {name: 'Mongolia', abbreviation: 'MN', emoji: '🇲🇳'},
    {name: 'Montenegro', abbreviation: 'ME', emoji: '🇲🇪'},
    {name: 'Morocco', abbreviation: 'MA', emoji: '🇲🇦'},
    {name: 'Mozambique', abbreviation: 'MZ', emoji: '🇲🇿'},
    {name: 'Myanmar', abbreviation: 'MM', emoji: '🇲🇲'},
    {name: 'Namibia', abbreviation: 'NA', emoji: '🇳🇦'},
    {name: 'Nauru', abbreviation: 'NR', emoji: '🇳🇷'},
    {name: 'Nepal', abbreviation: 'NP', emoji: '🇳🇵'},
    {name: 'Netherlands', abbreviation: 'NL', emoji: '🇳🇱'},
    {name: 'New Zealand', abbreviation: 'NZ', emoji: '🇳🇿'},
    {name: 'Nicaragua', abbreviation: 'NI', emoji: '🇳🇮'},
    {name: 'Niger', abbreviation: 'NE', emoji: '🇳🇪'},
    {name: 'Nigeria', abbreviation: 'NG', emoji: '🇳🇬'},
    {name: 'North Korea', abbreviation: 'KP', emoji: '🇰🇵'},
    {name: 'North Macedonia', abbreviation: 'MK', emoji: '🇲🇰'},
    {name: 'Norway', abbreviation: 'NO', emoji: '🇳🇴'},
    {name: 'Oman', abbreviation: 'OM', emoji: '🇴🇲'},
    {name: 'Pakistan', abbreviation: 'PK', emoji: '🇵🇰'},
    {name: 'Palau', abbreviation: 'PW', emoji: '🇵🇼'},
    {name: 'Palestine', abbreviation: 'PS', emoji: '🇵🇸'},
    {name: 'Panama', abbreviation: 'PA', emoji: '🇵🇦'},
    {name: 'Papua New Guinea', abbreviation: 'PG', emoji: '🇵🇬'},
    {name: 'Paraguay', abbreviation: 'PY', emoji: '🇵🇾'},
    {name: 'Peru', abbreviation: 'PE', emoji: '🇵🇪'},
    {name: 'Philippines', abbreviation: 'PH', emoji: '🇵🇭'},
    {name: 'Poland', abbreviation: 'PL', emoji: '🇵🇱'},
    {name: 'Portugal', abbreviation: 'PT', emoji: '🇵🇹'},
    {name: 'Qatar', abbreviation: 'QA', emoji: '🇶🇦'},
    {name: 'Romania', abbreviation: 'RO', emoji: '🇷🇴'},
    {name: 'Russia', abbreviation: 'RU', emoji: '🇷🇺'},
    {name: 'Rwanda', abbreviation: 'RW', emoji: '🇷🇼'},
    {name: 'Saint Kitts and Nevis', abbreviation: 'KN', emoji: '🇰🇳'},
    {name: 'Saint Lucia', abbreviation: 'LC', emoji: '🇱🇨'},
    {name: 'Saint Vincent and the Grenadines', abbreviation: 'VC', emoji: '🇻🇨'},
    {name: 'Samoa', abbreviation: 'WS', emoji: '🇼🇸'},
    {name: 'San Marino', abbreviation: 'SM', emoji: '🇸🇲'},
    {name: 'Sao Tome and Principe', abbreviation: 'ST', emoji: '🇸🇹'},
    {name: 'Saudi Arabia', abbreviation: 'SA', emoji: '🇸🇦'},
    {name: 'Senegal', abbreviation: 'SN', emoji: '🇸🇳'},
    {name: 'Serbia', abbreviation: 'RS', emoji: '🇷🇸'},
    {name: 'Seychelles', abbreviation: 'SC', emoji: '🇸🇨'},
    {name: 'Sierra Leone', abbreviation: 'SL', emoji: '🇸🇱'},
    {name: 'Singapore', abbreviation: 'SG', emoji: '🇸🇬'},
    {name: 'Slovakia', abbreviation: 'SK', emoji: '🇸🇰'},
    {name: 'Slovenia', abbreviation: 'SI', emoji: '🇸🇮'},
    {name: 'Solomon Islands', abbreviation: 'SB', emoji: '🇸🇧'},
    {name: 'Somalia', abbreviation: 'SO', emoji: '🇸🇴'},
    {name: 'South Africa', abbreviation: 'ZA', emoji: '🇿🇦'},
    {name: 'South Korea', abbreviation: 'KR', emoji: '🇰🇷'},
    {name: 'South Sudan', abbreviation: 'SS', emoji: '🇸🇸'},
    {name: 'Spain', abbreviation: 'ES', emoji: '🇪🇸'},
    {name: 'Sri Lanka', abbreviation: 'LK', emoji: '🇱🇰'},
    {name: 'Sudan', abbreviation: 'SD', emoji: '🇸🇩'},
    {name: 'Suriname', abbreviation: 'SR', emoji: '🇸🇷'},
    {name: 'Sweden', abbreviation: 'SE', emoji: '🇸🇪'},
    {name: 'Switzerland', abbreviation: 'CH', emoji: '🇨🇭'},
    {name: 'Syria', abbreviation: 'SY', emoji: '🇸🇾'},
    {name: 'Taiwan', abbreviation: 'TW', emoji: '🇹🇼'},
    {name: 'Tajikistan', abbreviation: 'TJ', emoji: '🇹🇯'},
    {name: 'Tanzania', abbreviation: 'TZ', emoji: '🇹🇿'},
    {name: 'Thailand', abbreviation: 'TH', emoji: '🇹🇭'},
    {name: 'Timor-Leste', abbreviation: 'TL', emoji: '🇹🇱'},
    {name: 'Togo', abbreviation: 'TG', emoji: '🇹🇬'},
    {name: 'Tonga', abbreviation: 'TO', emoji: '🇹🇴'},
    {name: 'Trinidad and Tobago', abbreviation: 'TT', emoji: '🇹🇹'},
    {name: 'Tunisia', abbreviation: 'TN', emoji: '🇹🇳'},
    {name: 'Turkey', abbreviation: 'TR', emoji: '🇹🇷'},
    {name: 'Turkmenistan', abbreviation: 'TM', emoji: '🇹🇲'},
    {name: 'Tuvalu', abbreviation: 'TV', emoji: '🇹🇻'},
    {name: 'Uganda', abbreviation: 'UG', emoji: '🇺🇬'},
    {name: 'Ukraine', abbreviation: 'UA', emoji: '🇺🇦'},
    {name: 'United Arab Emirates', abbreviation: 'AE', emoji: '🇦🇪'},
    {name: 'United Kingdom', abbreviation: 'GB', emoji: '🇬🇧'},
    {name: 'United States', abbreviation: 'US', emoji: '🇺🇸'},
    {name: 'Uruguay', abbreviation: 'UY', emoji: '🇺🇾'},
    {name: 'Uzbekistan', abbreviation: 'UZ', emoji: '🇺🇿'},
    {name: 'Vanuatu', abbreviation: 'VU', emoji: '🇻🇺'},
    {name: 'Vatican City', abbreviation: 'VA', emoji: '🇻🇦'},
    {name: 'Venezuela', abbreviation: 'VE', emoji: '🇻🇪'},
    {name: 'Vietnam', abbreviation: 'VN', emoji: '🇻🇳'},
    {name: 'Yemen', abbreviation: 'YE', emoji: '🇾🇪'},
    {name: 'Zambia', abbreviation: 'ZM', emoji: '🇿🇲'},
    {name: 'Zimbabwe', abbreviation: 'ZW', emoji: '🇿🇼'}
];

export default dbCountries;