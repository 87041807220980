import React, { useEffect ,useState } from 'react'
import { Box, Heading, Text,Image } from 'native-base';

//SOURCES 
import IconAndroid from '../../sources/icons/android.svg';
import IconApple from '../../sources/icons/apple.svg';
import IconModeration from '../../sources/icons/clock.svg';
import IconDownload from '../../sources/icons/download.svg';
import IconEvent from '../../sources/icons/event.svg';
import IconUser from '../../sources/icons/userIcon.svg';

//DB
import firebase from '../../db/firebase';

const HomeAdmin = () => {
    const [android, setAndroid] = useState(0);
    const [apple, setApple] = useState(110);
    const [data, setData] = useState([]);
    const [teamsModeration, setTeamsModeration] = useState([]);
    const [numberActives, setNumberActives] = useState(0);
    const [numberFinish, setNumberFinish] = useState(0);
    const [numberUsers, setNumberUsers] = useState(20);

    useEffect(() => {
        firebase.db.collection('teamsArgentina').onSnapshot(driveSnapshot);
        function driveSnapshot(snapshot) {
            let db = snapshot.docs.map( meet =>{
                return{
                    ...meet.data()
                }
            });
            setData(db);
        }
    }, []);
    
    useEffect(() => {
        firebase.db.collection('teamsArgentina').where('moderateStatus', '==', 'wait').onSnapshot(driveSnapshot);
        function driveSnapshot(snapshot) {
            let db = snapshot.docs.map( meet =>{
                return{
                    ...meet.data()
                }
            });
            setTeamsModeration(db);
        }
    }, [])
    
    useEffect(() => {
        let finishCount = 0;
        let activeCount = 0;
        setNumberActives(0);
        setNumberFinish(0);
        if (data) {
            data.forEach(team => {
                const date = new Date(team.dateMeet.seconds * 1000 + Math.round(team.dateMeet.nanoseconds / 1000000));
                const dateNow = new Date();
                if (date < dateNow) {
                    finishCount++;
                } else {
                    activeCount++;
                }
            });
        }
        setNumberFinish(prevNumberFinish => prevNumberFinish + finishCount);
        setNumberActives(prevNumberActives => prevNumberActives + activeCount);
    }, [data])

    
    return ( 
        <Box width={'100%'} height={'100%'}>
            <Box width={'100%'}  height={'50%'} flexDirection='row'>
                <div className='box-home' >
                    <Box flexDirection='row' alignItems='center'>
                        <Image mr={2} src={IconEvent} size={'xs'} fill='red' alt='Events'/>
                        <Heading>Events</Heading>
                    </Box>
                    <Box flexDirection='row' justifyContent={'space-around'} mt={2} w={'80%'}>
                        <Box alignItems='center' >
                            <Text bold fontSize={'24px'} >Activos</Text>
                            <Text bold fontSize={'24px'} color='green.500'>{numberActives}</Text>
                        </Box>
                        <Box alignItems='center' >
                            <Text bold fontSize={'24px'} >Terminados</Text>
                            <Text bold fontSize={'24px'} color='red.500'>{numberFinish}</Text>
                        </Box>
                    </Box>
                </div>
                <div className='box-home' >
                    <Box flexDirection='row' alignItems='center'>
                        <Image mr={2} src={IconDownload} size={'xs'}  alt='Downloads'/>
                        <Heading>Downloads</Heading>
                    </Box>
                    <Box flexDirection='row' justifyContent={'space-around'} mt={2} w={'80%'}>
                        <Box alignItems='center' >
                            <Image src={IconAndroid} size={'xs'} alt='android'/>
                            <Text bold fontSize={'24px'}>{android}</Text>
                        </Box>
                        <Box alignItems='center' >
                            <Image src={IconApple} size={'xs'} alt='android'/>
                            <Text bold fontSize={'24px'}>{apple}</Text>
                        </Box>
                    </Box>
                </div>
            </Box>
            <Box width={'100%'} height={'50%'} flexDirection='row'>
                <div className='box-home' >
                    <Heading>Moderation</Heading>
                    <Box flexDirection='row' justifyContent={'space-around'} mt={2} w={'80%'}>
                        <Box alignItems='center' >
                            <Image src={IconModeration} size={'xs'} alt='android'/>
                            <Text bold fontSize={'24px'}>{teamsModeration.length}</Text>
                        </Box>
                    </Box>
                </div>
                <div className='box-home' >
                    <Heading>Users</Heading>
                    <Box flexDirection='row' justifyContent={'space-around'} mt={2} w={'80%'}>
                        <Box alignItems='center' >
                            <Image src={IconUser} size={'xs'} alt='users'/>
                            <Text bold fontSize={'24px'}>{numberUsers}</Text>
                        </Box>
                    </Box>
                </div>
            </Box>
        </Box>
     );
}
 
export default HomeAdmin;