import React, { useContext, useEffect, useState } from 'react'
import {StyleSheet,View} from 'react-native'
import { Box, FlatList, Heading,Pressable,ScrollView,Text } from 'native-base';
import { FirebaseContext } from '../../db/firebase';
import Loader from '../../components/ui/Loader';
import TeamViewInfo from '../../components/ui/TeamViewInfo';
import ModalEditTeam from '../../components/modals/ModalEditTeam';

const MyTeams = () => {
    const {firebase} = useContext(FirebaseContext);
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [filter, setFilter] = useState('Active');
    const [numberActives, setNumberActives] = useState(0);
    const [numberFinish, setNumberFinish] = useState(0);
    const [modalEdit, setModalEdit] = useState(false);
    const [dataEdit, setDataEdit] = useState([]);

    useEffect(() => {
        firebase.db.collection('teamsArgentina').where('creator', '==', 'Team Admins').onSnapshot(driveSnapshot);
        function driveSnapshot(snapshot) {
            let db = snapshot.docs.map( meet =>{
                return{
                    ...meet.data(),
                    id: meet.id,
                }
            });
            setData(db);
        }
    }, []);

    useEffect(() => {
        let finishCount = 0;
        let activeCount = 0;
        setNumberFinish(0);
        setNumberActives(0);
        
        if (data) {
            data.forEach(team => {
                const date = new Date(team.dateMeet.seconds * 1000 + Math.round(team.dateMeet.nanoseconds / 1000000));
                const dateNow = new Date();
                if (date < dateNow) {
                    finishCount++;
                } else {
                    activeCount++;
                }
            });
        }
        setNumberFinish(prevNumberFinish => prevNumberFinish + finishCount);
        setNumberActives(prevNumberActives => prevNumberActives + activeCount);
    }, [data]);
    
   
    
    return ( 
        <Box w={'100%'} h={'100%'} px={10} pt={10}>
            <Loader showModal={loader}/>
            <ModalEditTeam showModal={modalEdit} setShowModal={setModalEdit} data={dataEdit}/>
            <Box mb={5} flexDirection={'row'} justifyContent={'space-between'} alignItems='center' borderBottomColor={'gray.700'} borderBottomWidth={2} borderBottomStyle={'solid'} pb={5}>
                <Box flexDirection={'row'} >
                    <Heading >My Teams </Heading>    
                </Box >
               
                    
                <Box flexDirection={'row'} >
                    {/* <Box flexDirection={'row'} alignItems={'center'}>
                        <Text fontSize='20px' mx={5} bold color={filter === 'All' ? 'orange.500' : 'gray.600'} onPress={() => setFilter('All')}>Todos</Text>
                        <Box bg={filter === 'All' ? 'orange.500' : 'gray.600'} mx={5} p={'10px'} shadow={2} borderRadius={'5%'}>
                            <Text color='white' fontSize='16px' bold>{data.length}</Text>
                        </Box>
                    </Box> */}
                    <Box flexDirection={'row'} alignItems={'center'}>
                        <Text fontSize='20px' mx={5} bold color={filter === 'Active' ? 'green.500' : 'gray.600'} onPress={() => setFilter('Active')}>Activos</Text>
                        <Box bg={filter === 'Active' ? 'green.500' : 'gray.600'} mx={5} p={'10px'} shadow={2} borderRadius={'5%'}>
                            <Text color='white' fontSize='16px' bold>{numberActives}</Text>
                        </Box>
                    </Box>
                    <Box flexDirection={'row'} alignItems={'center'}>
                        <Text fontSize='20px' mx={5} bold color={filter === 'Finish' ? 'red.500' : 'gray.600'} onPress={() => setFilter('Finish')}>Terminados</Text>
                        <Box bg={filter === 'Finish' ? 'red.500' : 'gray.600'} mx={5} p={'10px'} shadow={2} borderRadius={'5%'}>
                            <Text color='white' fontSize='16px' bold>{numberFinish}</Text>
                        </Box>
                    </Box>
                    
                </Box>
            </Box>

            <ScrollView>
            {/* <div className={filter === 'All'? 'teams-container' : 'class-none'}>
            {data? data.map(team => {
                return(
                    <Pressable onPress={() => {setModalEdit(true); setDataEdit(team);}} >
                        <TeamViewInfo team={team} setLoader={setLoader}/>
                    </Pressable>
                );
            }):null}
        
              
            </div> */}
            <div className={filter === 'Active'? 'teams-container' : 'class-none'}>

                {data? data.map(team => {
                    const date = new Date(team.dateMeet.seconds * 1000 + Math.round(team.dateMeet.nanoseconds / 1000000));
                    const dateNow = new Date();
                    if (date < dateNow) {
                        return null;
                    } else {
                        return(
                            <Pressable onPress={() => {setModalEdit(true); setDataEdit(team);}} >
                                <TeamViewInfo team={team} setLoader={setLoader}/>
                            </Pressable>
                        );
                    }
                }):(
                    <span class="loader"></span>
                )}
              
            </div>
            <div className={filter === 'Finish'? 'teams-container' : 'class-none'}>

                {data? data.map(team => {
                    const date = new Date(team.dateMeet.seconds * 1000 + Math.round(team.dateMeet.nanoseconds / 1000000));
                    const dateNow = new Date();
                    if (date < dateNow) {
                        return(
                            <Pressable onPress={() => {setModalEdit(true); setDataEdit(team);}} >
                                <TeamViewInfo team={team} setLoader={setLoader}/>
                            </Pressable>
                        );
                        } else {
                        return null;
                    }
                }):(
                    <span class="loader"></span>
                )}
              
            </div>
            </ScrollView>

        </Box>
     );
}
const styles = StyleSheet.create({
    flatListContainer: {
      paddingHorizontal: 10,
      paddingTop: 10,
      width:'100%',
      
    },
    loader: {
      // Define your loader styles here
    },
  });
export default MyTeams;