import React, { useEffect, useState } from 'react'
import { Box, Button, Heading, Image, Modal,Pressable,Text } from 'native-base';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import IconCross from '../../sources/icons/rejected.svg';
import Geocode from 'react-geocode';

const MapSelect = ({showModal,setShowModal,google,dataTeam,setChanges}) => {
    const [currentPosition, setCurrentPosition] = useState({lat:-31.429323108750776,lng:-64.18752323652237});
    const [dataLocation, setDataLocation] = useState({
        latitude:0 ,
        longitude:0,
        country:'',
        address:'',
        state:'',
        city:''
    })
    
    const handleMapClick = (mapProps, map, clickEvent) => {
        const { latLng } = clickEvent;
        setCurrentPosition({
            lat: latLng.lat(),
            lng: latLng.lng()
        })
    }

    useEffect(() => {
        Geocode.setApiKey('AIzaSyDF-EFxXOknVVe0WHjXM0gMi0ycLQAU9uU');
        Geocode.fromLatLng(currentPosition.lat, currentPosition.lng)
          .then(response => {

            const address = response.results[0].formatted_address;

            const addressData = response.results[0].address_components;

            const cityData = addressData.find(component =>
                component.types.includes('locality')
            );
            const stateData = addressData.find(component =>
                component.types.includes('administrative_area_level_1')
            );
            const countryData = addressData.find(component =>
                component.types.includes('country')
            );
            setDataLocation({latitude:currentPosition.lat,longitude:currentPosition.lng,country:countryData.long_name,state:stateData.long_name,city:cityData.long_name,address:address})
          })
          .catch(error => {
            console.error('Error al obtener la dirección:', error);
          });
      }, [currentPosition]);


    return ( 
        <Modal isOpen={showModal} size={'full'} px={'100px'} h={'100%'}>
            <Modal.Content >
                <Modal.Header flexDirection='row' justifyContent={'space-between'} alignItems='center'>
                    <Heading >Selecionar ubicacion</Heading>
                    <Pressable onPress={() => setShowModal(false)}>
                        <Image source={IconCross} size={'xs'} alt='Back' />

                    </Pressable>
                </Modal.Header>
                <Modal.Body h='100%' p={0} >
                    <div style={{height:'500px'}}>
                        <Map
                            google={google}
                            onClick={handleMapClick}
                            initialCenter={{
                                lat: -31.429323108750776,
                                lng: -64.18752323652237,
                            }}
                            zoom={14}
                        >
                            
                            {currentPosition && (
                                <Marker
                                    position={currentPosition}
                                />
                            )}
                        </Map>
                    </div>
                </Modal.Body>
                <Modal.Footer justifyContent='space-between' alignItems='center'>
                    <Box flexDirection='row'>
                        <Box mr={2}>
                            <Text>Country: {dataLocation.country}</Text>
                            <Text>State: {dataLocation.state}</Text>
                            <Text>City: {dataLocation.city}</Text>
                        </Box>
                        <Box>
                            <Text>Lat: {dataLocation.latitude}</Text>
                            <Text>Lon: {dataLocation.longitude}</Text>
                            <Text>Address: {dataLocation.address}</Text>
                        </Box>
                    </Box>
                    <Button bg='orange.500' onPress={() => {
                        setChanges({
                            ...dataTeam,
                            location:{latitude:dataLocation.latitude,longitude:dataLocation.longitude},
                            countryLocation:dataLocation.country,
                            state:dataLocation.state,
                            address:dataLocation.address,
                            city:dataLocation.city,
                        })
                        setShowModal(false);
                    }}>
                        <Text color={'white'} bold>GUARDAR</Text>
                    </Button>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
     );
}
 
export default GoogleApiWrapper({
  apiKey: 'AIzaSyDF-EFxXOknVVe0WHjXM0gMi0ycLQAU9uU'
})(MapSelect);