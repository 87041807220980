import { Box, Heading, Modal, Spinner } from 'native-base';
import React from 'react'

const Loader = ({showModal}) => {
    return ( 
        <Modal isOpen={showModal}>
            <Modal.Content>
                <Modal.Body>
                    <Box flexDirection='row' justifyContent='center' alignItems='center'>
                        <Spinner mr={2} size={'lg'} color='orange.500'/>
                        <Heading color='orange.500'>Cargando...</Heading>
                    </Box>
                </Modal.Body>
            </Modal.Content>
        </Modal>
     );
}
 
export default Loader;