import React, {useState} from 'react'
import TeamsContext from './teamsContext';
import firebase from '../../db/firebase';

const TeamsState = props => {
    const [team, setTeam] = useState({});
    
    
    const getTeam = async (id,country) => {
        const res = await firebase.db.collection('teams' + country.replace(/\s/g, "")).doc(id).get();
        let db = {
            ...res.data(),
            id: res.id,
        };
        setTeam(db);
        
    }
    return ( 
        
        <TeamsContext.Provider
            value={{
                team:team,
                getTeam
            }}
        >
            {props.children}
        </TeamsContext.Provider>

     );
}
 
export default TeamsState;
