import React, {useContext} from 'react'
import { Button, Modal,Text } from 'native-base';
import { FirebaseContext } from '../../db/firebase';

const AlertDialogDelete = ({showAlert,setShowAlert,team,setLoader}) => {
    const {firebase} = useContext(FirebaseContext);

    const deleteTeam = async () =>{
    
        setLoader(true);
        await firebase.db.collection('teams'+team.countryLocation.replace(/\s/g, "")).doc(team.id).delete().catch(err => console.log(err));
        await firebase.storage.ref(team.imgUrl).delete().catch(err => console.log(err));
        await firebase.db.collection('teamsAdmin').doc(team.id).delete().catch(err => console.log(err));
        setShowAlert(false);
        setLoader(false);
              
    }
    
    return ( 
        <Modal isOpen={showAlert} >
        <Modal.Content>
          <Modal.Header>Eliminar Team</Modal.Header>
          <Modal.Body>
            <Text>Seguro que deseas eliminar el team "<Text bold>{team.name}</Text>"?</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button variant="unstyled" colorScheme="coolGray" onPress={() => setShowAlert(false)} >
                Cancelar
              </Button>
              <Button colorScheme="danger" onPress={() => deleteTeam()}>
                Eliminar -
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
     );
}
 
export default AlertDialogDelete;