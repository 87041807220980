import React from 'react'
// Router
import {
    BrowserRouter,
    Routes ,
    Route,
} from "react-router-dom";
//Pages
import Home from './pages/Home';
import Terms from './pages/Terms';
import DashboardAdmin from './pages/admin/DashboardAdmin';
import LoginAdmin from './pages/admin/LoginAdmin';
import MyTeams from './pages/MyTeams';
import Team from './pages/Team';
const Router = () => {
    return ( 
        <BrowserRouter>
            <Routes>
                <Route path='/' exact element={<Home/>}/>
                <Route path='/terms' element={<Terms/>}/>
                <Route path='/team/:country/:id' element={<Team mobile={false}/>}/>
                <Route path='/my-teams' element={<MyTeams/>}/>
                <Route path='/dashboardAdmin' element={<DashboardAdmin/>}/>
                <Route path='/loginAdmin' element={<LoginAdmin/>}/>
            </Routes>
        </BrowserRouter>
     );
}
 
export default Router;