import React, {useEffect,useState, useRef} from 'react'
import { Box, Button, Heading, Image, Modal,Pressable,Text } from 'native-base';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import IconCross from '../../sources/icons/rejected.svg';
import Geocode from 'react-geocode';
import IconPoint from '../../sources/icons/iconPoint.svg'


const MapView = ({google,points,height,selectLocation}) => {
    const [currentPosition, setCurrentPosition] = useState({lat:-31.429323108750776,lng:-64.18752323652237});
    const darkMapStyles = [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#242f3e',
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#242f3e',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#746855',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#38414e',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9ca5b3',
            },
          ],
        },
        // Ocultar puntos de interés, como los pines de tiendas
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        // Agrega más estilos según sea necesario para adaptarse al tema oscuro
      ];
    
    const handleMapClick = (mapProps, map, clickEvent) => {
        const { latLng } = clickEvent;
        setCurrentPosition({
            lat: latLng.lat(),
            lng: latLng.lng()
        })
    }
    const mapRef = useRef(null); // Referencia al objeto de mapa

    useEffect(() => {
        Geocode.setApiKey('AIzaSyDF-EFxXOknVVe0WHjXM0gMi0ycLQAU9uU');
        Geocode.fromLatLng(currentPosition.lat, currentPosition.lng)
          .then(response => {

            const address = response.results[0].formatted_address;

            const addressData = response.results[0].address_components;

            const cityData = addressData.find(component =>
                component.types.includes('locality')
            );
            const stateData = addressData.find(component =>
                component.types.includes('administrative_area_level_1')
            );
            const countryData = addressData.find(component =>
                component.types.includes('country')
            );
          })
          .catch(error => {
            console.error('Error al obtener la dirección:', error);
          });
      }, [currentPosition]);

      useEffect(() => {
        setCurrentPosition(selectLocation)
      }, [selectLocation])

      useEffect(() => {
        // Navega hasta la nueva posición cuando cambia currentPosition
        if (mapRef.current) {
          mapRef.current.map.panTo({ lat: currentPosition.lat, lng: currentPosition.lng });
        }
      }, [currentPosition]);
      
    return ( 
        <Box h={height}>
            <div style={{height:'100%'}}>
                <Map
                    google={google}
                    onClick={handleMapClick}
                    initialCenter={{
                        lat: currentPosition.lat,
                        lng: currentPosition.lng,
                    }}
                   
                    zoom={15}
                    styles={darkMapStyles} // Aplica el tema oscuro al mapa

                >
                {points?points.map(point =>{
                    return(
                        <Marker 
                            key={point.id}
                            position={{lat:point.location.latitude,lng:point.location.longitude}}
                            icon={{
                                url: IconPoint,
                                
                            }}
                            className="map-marker" 
                        />
                    );
                }):null}
                    
                   
                </Map>
            </div>
        </Box>
     );
}
 
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDF-EFxXOknVVe0WHjXM0gMi0ycLQAU9uU'
  })(MapView);